import React from 'react';
import styles from './Modal.module.css';
import Card from '../Card';
import { Button, ButtonIcon } from '../Button';
import { MdClose } from 'react-icons/md';
import classNames from 'classnames';
import _ from 'lodash';

const Modal = ({
  width = 'fit-width',
  height = 'fit-height',
  show = false,
  closable = false,
  actions = [],
  title = '',
  btnTitle = {},
  children,
  onClose = () => {}
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames([styles.modalPosition])}
      id={title ? `modal-${title}` : 'modal'}>
      <div
        className={classNames([styles.modalContainer])}
        onClick={() => onClose()}></div>
      <Card
        className={classNames([styles.overlayModalOpen])}
        width={width}
        height={height}>
        <div>
          <div
            className={classNames([
              styles.headerModal,
              closable && !title ? styles.closeButton : ''
            ])}>
            {title ? (
              <div
                className={classNames([
                  styles.titleModal,
                  !_.isEmpty(btnTitle)
                    ? styles.titleModalWithIcon
                    : styles.titleModalWithOutIcon
                ])}>
                {title}
              </div>
            ) : null}
            {!_.isEmpty(btnTitle) && (
              <div className={classNames(['fit-width'])}>{btnTitle}</div>
            )}
            {closable ? (
              <ButtonIcon onClick={() => onClose()}>
                <MdClose />
              </ButtonIcon>
            ) : null}
          </div>
          <div id="text-modal" className={classNames([styles.contentModal])}>
            {children}
          </div>
        </div>
        {actions.length > 0 ? (
          <div className={classNames([styles.actionsButton])}>
            {actions.map((action, index) => (
              <Button
                isLoading={action.isLoading}
                id={`btn-modal-${action.label}-${index}`}
                key={action.label}
                className={classNames([
                  index === actions.length - 1
                    ? styles.lastActionButton
                    : styles.actionButtonEven
                ])}
                onClick={() => action.action()}
                ghost={!action.primary && index === 0 && actions.length > 1}
                outline={index > 0 && index < actions.length - 1}
                disabled={action.disabled !== null ? action.disabled : false}>
                {action.label}
              </Button>
            ))}
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export default Modal;
