import React, { useContext, useState, useEffect } from 'react'
import ChevronDownIcon from '../icons/chevron-down';
import { MenuContext } from './menu-state'

export default function MenuEntry({IconComponent, iconSize, label, description, id, onClick, subEntries, isAnchor, active}){

    const [subOpen, setSubOpen] = useState(false)

    const menu = useContext(MenuContext)
    const entryId = id || Math.random().toString(36).substring(7);

    const setOnHover = !subEntries || (subEntries && !subOpen)

    useEffect(() => {

        if(menu.open.value === false){
            setSubOpen(false)
            return
        }

        // eslint-disable-next-line
    }, [menu.open.value])

    useEffect(() => {
        
        setSubOpen(menu.expand.value === true)

        // eslint-disable-next-line
    }, [menu.expand.value])

    useEffect(() => {

        if(!isAnchor) return

        const entry = document.getElementById(entryId)

        if(!entry) return

        setTimeout(() => {
            document.getElementById('menu-area').scrollTo(0 ,entry.offsetTop)
        }, 100)

        // eslint-disable-next-line
    }, [])

    return(
    <div className={`menu-entry ${setOnHover ? 'hoverable' : ''}${active ? ' active': ''} clickable`} key={entryId} id={entryId} onClick={() => {

        if(onClick) onClick()

        if(subEntries) {
            setSubOpen(!subOpen)
            return
        }

        menu.open.set(false)
    }}
    >
        <IconComponent size={iconSize} color='white'></IconComponent>
        <div className='menu-entry-text'>
            <label>{label}</label>
            <span>{description}</span>
        </div>

        {subOpen ? <div className='submenu'>{subEntries}</div> : null}

        {subEntries && <div className={`menu-down-icon ${subOpen ? 'open' : ''}`}><ChevronDownIcon></ChevronDownIcon></div>}

    </div>
    )
}