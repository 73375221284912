import React, { useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../libs/conecte-se/ui-components/Pagination";
import Table from "../../libs/conecte-se/ui-components/Table";
import classNames from "classnames";
import styles from "./TableWithPagination.module.css";

const TableWithPagination = ({
  paginationData,
  rows,
  columns,
  keyToCompare,
  useKeyToCompareAsId,
  selectedRows,
  disableSelection,
  isToShowSkeletons,
  rowsPerPageOptions,
  minRowsToShowPagination,
  isFetchingData,
  firstFetchWasMade,
  paginationRequestData,
  isChangingPage,
  loadingTypeOfRowsPerPage,
  keyOfItemsOfPaginationTable,
  noSelection,
  hasStatus,
  onSelectRows,
  onPageChange,
  onRowsPerPageChange,
  renderSkeletonsOfTable,
  setPaginationRequestData,
  setIsChangingPage,
  setLoadingTypeOfRowsPerPage,
  setPaginationObject,
  className,
  ...props
}) => {
  const getRowsPerPageOptions = () => {
    if (rowsPerPageOptions?.length) return rowsPerPageOptions;
    return [10, 25, 50, 75, 100];
  };

  const canPaginationDisplay = () => {
    if (minRowsToShowPagination === undefined) return true;
    return paginationData?.total_filtered_items > minRowsToShowPagination;
  };

  const setLoadingVariables = useCallback(() => {
    if (isChangingPage) {
      setIsChangingPage(false);
    }
    if (loadingTypeOfRowsPerPage) {
      setLoadingTypeOfRowsPerPage({
        type: "success",
        isFirstPage: true,
      });
    }
  }, [
    isChangingPage,
    loadingTypeOfRowsPerPage,
    setIsChangingPage,
    setLoadingTypeOfRowsPerPage,
  ]);

  const handleChangePage = (newPage) => {
    setIsChangingPage(true);
    setPaginationRequestData((prev) => ({
      ...prev,
      page_number: newPage,
    }));
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const changeLoadingTypeOfRowsPerPage = (loadingType) => {
    setLoadingTypeOfRowsPerPage({
      type: loadingType,
      isFirstPage: paginationRequestData.page_number === 1,
    });
  };

  const changeRowsPerPage = (newRows) => {
    setPaginationRequestData((prev) => ({
      ...prev,
      page_number: 1,
      page_size: newRows,
    }));
    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRows);
    }
  };

  const handleChangeRowsPerPage = (newRows) => {
    if (
      newRows < paginationRequestData.page_size &&
      paginationData.current_page === 1
    ) {
      setPaginationObject((prev) => {
        const arrayOfPaginationItems = prev[keyOfItemsOfPaginationTable];
        arrayOfPaginationItems.length = newRows;
        prev[keyOfItemsOfPaginationTable] = arrayOfPaginationItems;
        return prev;
      });
      changeLoadingTypeOfRowsPerPage("cutting");
      changeRowsPerPage(newRows);
      return;
    }
    changeLoadingTypeOfRowsPerPage("increasing");
    changeRowsPerPage(newRows);
  };

  useEffect(() => {
    setLoadingVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const isToShowSkeletonsOnRowsPerPageChange =
    loadingTypeOfRowsPerPage.isFirstPage &&
    loadingTypeOfRowsPerPage.type === "increasing";

  const isToShowSkeletonOnLoading =
    !firstFetchWasMade ||
    isChangingPage ||
    isFetchingData ||
    !!isToShowSkeletons ||
    !loadingTypeOfRowsPerPage.isFirstPage;

  return (
    <div
      className={classNames([styles.tableWithPagination, className])}
      {...props}
    >
      {isToShowSkeletonOnLoading && renderSkeletonsOfTable ? (
        <div className="d-flex flex-column">
          <Skeleton count={1} height={50} borderRadius={10} className="mb-3" />
          {renderSkeletonsOfTable(paginationRequestData.page_size)}
        </div>
      ) : (
        <Table
          hasStatus={hasStatus}
          noSelection={noSelection}
          columns={columns}
          rows={rows}
          onSelectRows={onSelectRows}
          keyToCompare={keyToCompare}
          useKeyToCompareAsId={useKeyToCompareAsId}
          selectedRows={selectedRows}
          disableSelection={disableSelection}
        />
      )}
      {isToShowSkeletonsOnRowsPerPageChange
        ? renderSkeletonsOfTable &&
          renderSkeletonsOfTable(
            paginationRequestData.page_size ||
              0 - paginationData.total_filtered_items ||
              0
          )
        : null}
      <div className="d-flex justify-end mt-3">
        {canPaginationDisplay() ? (
          <Pagination
            count={paginationData?.total_filtered_items}
            pageIndex={paginationRequestData.page_number}
            rowsPerPageOptions={getRowsPerPageOptions()}
            onPageChange={handleChangePage}
            rowsPerPage={paginationRequestData.page_size}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TableWithPagination;
