import React, {useState} from 'react'

import Button from '../button/button'

export const FormContext = React.createContext()

export default function Form({id, submitText, onSubmit, children, hideButton, disableButton, buttonId, stopEventPropagation, isPristine = true}){

    const [errorsList, setErrorsList] = useState([])
    const [collapsibleErrorsList, setCollapsibleErrorsList] = useState([])
    const [pristine, setPristine] = useState(isPristine)
    const [validators, setValidators] = useState({})

    const runValidators = async() => {

        let hasErrors = false
        let errors = []

        for(let key of Object.keys(validators)){
            if(!(await validators[key].fn(validators[key].params))){
                errors.push(key)
                hasErrors = true
            }
        }

        setErrorsList([...errors])
        return hasErrors
    }

    const setValidatorsContext = (key, validatorsFn, params) => {
        setValidators(prev => {
            prev[key] = {fn: validatorsFn, params: params}
            return {...prev}
        })
    }

    const removeValidators = (key) => {
        setValidators(prev => {
            delete prev[key]
            return {...prev}
        })
    }

    const submit = async(e) => {

        e.preventDefault()
        if(stopEventPropagation)
            e.stopPropagation()

        if (e.key === "Enter" && (hideButton || disableButton))
            return

        let values = {}

        let formHasErrors = await runValidators()
        if(formHasErrors)
            return

        if(Symbol.iterator in children){
            for(let child of children){

                if(!child) continue

                if(child.type === 'input' && !child.props.name)
                    console.warn("Form: all fields needs to provide the 'name' prop in order to onSubmit work properly")

                if(child.props)
                    values[child.props.name] = child.props.value
            }
        }

        if(onSubmit)
            onSubmit(values)
    }

    const addError = (name, collapsible) => {
        if(!errorsList.includes(name)) {
            errorsList.push(name)
            if (collapsible && !collapsibleErrorsList.includes(name)) {
                collapsibleErrorsList.push(name)
            }
        }

        setErrorsList([...errorsList])
        setCollapsibleErrorsList([...collapsibleErrorsList])
    }

    const subError = (name, collapsible) => {
        setErrorsList(
            [...errorsList.filter((v) => v !== name)]
        )
        if (collapsible)
            setCollapsibleErrorsList(
                [...collapsibleErrorsList.filter((v) => v !== name)]
            )
    }

    const hasErrors = () => {
        return errorsList.length !== 0
    }

    const hasCollapsibleErrors = () => {
        return collapsibleErrorsList.length !== 0
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter" && !hasErrors() && !disableButton) {
            submit(e)
        }
    }

    const clearErrors = () => {
        setErrorsList([])
    }

    return(
    <form id={id ? id : ''} onSubmit={submit} onKeyPress={handleKeyPress}>

        <FormContext.Provider value={{
            setPristine: setPristine,
            pristine: pristine,
            setValidators: setValidatorsContext,
            removeValidators: removeValidators,
            addError: addError,
            subError: subError,
            runValidators: runValidators,
            clearErrors: clearErrors,
            hasCollapsibleErrors: hasCollapsibleErrors
        }}>

            {children}

            {!hideButton ? <Button id={buttonId || null}
                text={submitText ? submitText : 'Salvar'}
                disabled={hasErrors() || pristine || disableButton}
            ></Button> : null}

        </FormContext.Provider>

    </form>)
}