import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../libs/conecte-se/ui-components/Button";
import Tag from "../../../libs/conecte-se/ui-components/Tags";
import styles from "./HeaderDeviceDetails.module.css";
import ItemAndDescription from "../ItemAndDescription";
import classNames from "classnames";
import RestartDeviceModal from "../RestartDeviceModal";
import DeleteDeviceModal from "../DeleteDeviceModal";
import UpdateFirmwareModal from "../UpdateFirmwareModal";
import ConfirmUpdateModal from "../ConfirmUpdateModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Tooltip from "../../../libs/conecte-se/ui-components/Tooltip";

function HeaderDeviceDetails({
  selectedDevice,
  isFetchedAfterMountDeviceInfo,
  refetchDeviceInfo,
  hasUpdateFirmware = false,
  hasRebootDevice = false,
  hasConnectedClient = false,
  hasRX = false,
  hasWireless = false,
}) {
  const [isRebooting, setIsRebooting] = useState(false);

  const statusColors = {
    online: "green",
    offline: "red",
  };

  const [showRestartDeviceModal, setShowRestartDeviceModal] = useState(false);

  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);

  const [showUpdateFirmwareModal, setShowUpdateFirmwareModal] = useState(false);
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState(null);

  const history = useHistory();

  const onCloseRestartDeviceModal = () => {
    setShowRestartDeviceModal(false);
  };

  const onCloseDeleteDeviceModal = () => {
    setShowDeleteDeviceModal(false);
  };

  return (
    <>
      {hasUpdateFirmware && (
        <>
          <UpdateFirmwareModal
            onClose={() => setShowUpdateFirmwareModal(false)}
            show={showUpdateFirmwareModal}
            setSelectedFirmwareVersion={setSelectedFirmwareVersion}
            device={selectedDevice?.device}
          />

          <ConfirmUpdateModal
            show={selectedFirmwareVersion !== null}
            onClose={() => setSelectedFirmwareVersion(null)}
            equipamentName={selectedDevice?.device?.alias}
            placeId={selectedDevice?.device?.place_id}
            selectedFirmwareVersion={selectedFirmwareVersion}
            onSucess={() => {
              setSelectedFirmwareVersion(null);
              refetchDeviceInfo();
            }}
          />
        </>
      )}

      <RestartDeviceModal
        selectedDevices={[selectedDevice?.device]}
        show={showRestartDeviceModal}
        onClose={onCloseRestartDeviceModal}
        onCancel={onCloseRestartDeviceModal}
        isRebooting={isRebooting}
        setIsRebooting={setIsRebooting}
      />

      <DeleteDeviceModal
        show={showDeleteDeviceModal}
        onCancel={onCloseDeleteDeviceModal}
        onClose={onCloseDeleteDeviceModal}
        selectedDevice={selectedDevice?.device}
        setDeviceIsDeleting={false}
      />

      <div className="row">
        <div className="col-xs-12">
          <div className="row">
            <div className="d-flex align-end col-xs-12">
              {isFetchedAfterMountDeviceInfo ? (
                <div>
                  <img
                    width={162}
                    id="image-device"
                    src={selectedDevice?.device?.image}
                    alt="Foto do equipamento"
                  />
                </div>
              ) : (
                <Skeleton width={162} height={162} />
              )}
              <div className="d-flex flex-column ml-9 fit-width">
                <div className="d-flex justify-end">
                  {isFetchedAfterMountDeviceInfo ? (
                    <>
                      <Button
                        ghost
                        id="btn-back"
                        onClick={() => {
                          const historyState = history.location;

                          if (historyState.state === undefined) {
                            history.push("/inmaster/query-equipaments");
                          } else {
                            history.push(historyState.state.prevPath);
                          }
                        }}
                      >
                        {"VOLTAR"}
                      </Button>
                      <Button
                        className="ml-2"
                        outline
                        id="btn-delete-device"
                        onClick={() => setShowDeleteDeviceModal(true)}
                        disabled={
                          // selectedDevice?.device?.status === 'offline' ||
                          !isFetchedAfterMountDeviceInfo || isRebooting
                        }
                      >
                        {"EXCLUIR"}
                      </Button>
                      {hasUpdateFirmware && (
                        <Button
                          className="ml-2"
                          secondary
                          id="btn-update-firmware-device"
                          disabled={
                            // selectedDevice?.device?.status === 'offline' ||
                            !isFetchedAfterMountDeviceInfo || isRebooting
                          }
                          onClick={() => setShowUpdateFirmwareModal(true)}
                        >
                          {"ATUALIZAR FIRMWARE"}
                        </Button>
                      )}
                      {hasRebootDevice && (
                        <Button
                          className="ml-2"
                          id="btn-reboot-device"
                          disabled={
                            // selectedDevice?.device?.status === 'offline' ||
                            !isFetchedAfterMountDeviceInfo || isRebooting
                          }
                          onClick={() => setShowRestartDeviceModal(true)}
                        >
                          {isRebooting
                            ? "REINICIANDO..."
                            : "Reiniciar Equipamento"}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Skeleton width={98} height={45} className="mr-2" />
                      <Skeleton width={101} height={45} className="mr-2" />
                      {hasUpdateFirmware && (
                        <Skeleton width={200} height={45} className="mr-2" />
                      )}
                      {hasRebootDevice && <Skeleton width={215} height={45} />}
                    </>
                  )}
                </div>
                <div className="mb-2 d-flex align-center">
                  <>
                    <h1
                      id="name-device"
                      className={classNames([
                        styles.deviceName,
                        "title-xl-base",
                      ])}
                    >
                      {selectedDevice.device?.alias}
                    </h1>
                  </>
                </div>
                <div className={styles.deviceInfos}>
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={"MAC"}
                    medium
                    description={selectedDevice.device?.mac}
                  />
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={"Versão de firmware"}
                    medium
                    description={selectedDevice.device?.firmware_version}
                  />
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={"Modelo"}
                    medium
                    description={selectedDevice.device?.model}
                  />
                  {hasConnectedClient && (
                    <ItemAndDescription
                      isLoading={!isFetchedAfterMountDeviceInfo}
                      title={"Clientes Conectados"}
                      medium
                      description={
                        selectedDevice.device?.status === "offline"
                          ? "0"
                          : String(selectedDevice.device?.connected_clients)
                      }
                    />
                  )}
                  {hasRX && (
                    <ItemAndDescription
                      isLoading={!isFetchedAfterMountDeviceInfo}
                      title={"Quantidade RX"}
                      medium
                      description={
                        selectedDevice.device?.status === "offline"
                          ? "0"
                          : String(selectedDevice.device?.connected_clients)
                      }
                    />
                  )}
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={"Status"}
                    medium
                    description={
                      <div className={styles.tags}>
                        <Tag
                          color={statusColors[selectedDevice.device?.status]}
                          wide
                        >
                          {selectedDevice.device?.status}
                        </Tag>
                        {hasWireless &&
                        selectedDevice.wireless_list?.length === 0 ? (
                          <Tooltip
                            text="Equipamento sem wireless"
                            toolTipStyle={{ width: 190 }}
                          >
                            <Tag color="wine" wide>
                              sem wireless
                            </Tag>
                          </Tooltip>
                        ) : null}
                      </div>
                    }
                  />
                </div>
                {/* {selectedDevice?.device?.status === 'offline' ? (
                      <div className="mt-4">
                        <Alert type="warning">
                          {t(
                            'Equipamentos offline não podem ter suas configurações alteradas'
                          )}
                        </Alert>
                      </div>
                    ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderDeviceDetails;
