export const generateColumns = (
    field = '',
    headerName = '',
    align =  'left', // | 'right' | 'center',
    width = 0,
    invisible = false
  ) => {
    return {
      field,
      headerName,
      align, // center || left || right
      width,
      invisible
    };
  };
  