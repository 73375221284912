import classNames from "classnames";
import React from "react";
import styles from "./Tooltip.module.css";

function Tooltip({
  children,
  text = "",
  disabled = false,
  positionY = "bottom", // center, top, bottom
  positionX = "middle", //right, left, end, start, middle
  toolTipStyle = {},
  id = "",
  ...props
}) {
  return (
    <div
      {...props}
      className={classNames([
        styles.shellTooltip,
        positionX === "middle" ? styles.shellTooltipMiddle : "",
      ])}
    >
      {disabled ? null : (
        <span
          id={id}
          style={toolTipStyle}
          className={classNames([
            styles.tooltip,
            styles[positionX],
            styles[positionY],
          ])}
        >
          {text}
        </span>
      )}
      {children}
    </div>
  );
}

export default Tooltip;
