import React, { useState } from 'react'

export const MainHeaderContext = React.createContext()

export default function MainHeaderGlobalState({children}){

    const [title, setTitle] = useState('')
    const [backRoute, setBackRoute] = useState('')
    const [extended, setExtended] = useState(false)
    const [extendedContent, setExtendedContent] = useState(null)
    const [collapsed, setCollapsed] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [navigation, setNavigation] = useState(true)
    const [notifications, setNotifications] = useState(true)
    const [titleAlwaysVisible, setTitleAlwaysVisible] = useState(true)

    return <MainHeaderContext.Provider value={{
        title: {value: title, set: setTitle},
        hidden: {value: hidden, set: setHidden},
        extended: {value: extended, set: setExtended},
        extendedContent: {value: extendedContent, set: setExtendedContent},
        collapsed: {value: collapsed, set: setCollapsed},
        backRoute: {value: backRoute, set: setBackRoute},
        navigation: {value: navigation, set: setNavigation},
        notifications: {value: notifications, set: setNotifications},
        titleAlwaysVisible: {value: titleAlwaysVisible, set: setTitleAlwaysVisible},
    }}>
        {children}
    </MainHeaderContext.Provider>
}