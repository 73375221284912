const Phonevalidator = {
    phone: (value) => {

        value = value.replace(/\(|\)|-| /gi, '')

        if(value.length < 10 || value.length > 12){
            return 'O telefone deve conter ddd + número'
        }

        return ''
    }

}

export default Phonevalidator