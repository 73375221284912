import React from 'react'

export default function EyeIcon(){

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#92A4B1" fillRule="nonzero">
                    <g>
                        <g>
                            <path d="M12 9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3m0-4.5c5 0 9.27 3.11 11 7.5-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12c1.65 3.36 5.06 5.5 8.82 5.5 3.76 0 7.17-2.14 8.82-5.5-1.65-3.36-5.06-5.5-8.82-5.5-3.76 0-7.17 2.14-8.82 5.5z" transform="translate(-1266.000000, -388.000000) translate(855.000000, 376.000000) translate(411.000000, 12.000000)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}