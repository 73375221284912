import React, { useContext, useEffect, useState } from "react";
import { EmptyMessage } from "../../components/EmptyMessage";
import { Input } from "../../libs/conecte-se/ui-components/Input";
import Card from "../../libs/conecte-se/ui-components/Card";
import { generateColumns } from "../../utils/tableUtils";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import { ResultsNotFoundMessage } from "../../components/ResultsNotFoundMessage";
import Table from "../../libs/conecte-se/ui-components/Table";
import styles from "./Members.module.css";
import classNames from "classnames";
import { MdSearch } from "react-icons/md";
import { InputIconInside } from "../../libs/conecte-se/ui-components/InputWrapper";
import Label from "../../libs/conecte-se/ui-components/Label";
import { MainHeaderContext } from "../../../../components/main-header/main-header-state";
import {
  Breadcrumbs,
  Crumb,
} from "../../libs/conecte-se/ui-components/Breadcrumbs";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { api } from "../../services/api/api";
import { useQuery } from "react-query";

export const getTagText = (role) => {
  if (role === "guest") {
    return "Convidado";
  }
  if (role === "manager") {
    return "Gerente";
  }

  return "Proprietário";
};

const MembersPage = () => {
  const { id: idSite } = useParams();

  const [members, setMembers] = useState([]);

  const [selectedSite, setSelectedSite] = useState({});

  const [search, setSearch] = useState("");

  const getMembers = () => {
    return api.sites.getMembersBySites({ siteId: idSite });
  };

  const { isFetchedAfterMount } = useQuery(["members"], getMembers, {
    cacheTime: 0,
    onSuccess: ({ data }) => {
      const membersWithTranslatedRole = data.members.map((member) => {
        return {
          ...member,
          user: {
            ...member.user,
            name: member.user.name || "-",
          },
          translatedRole: getTagText(member.role),
        };
      });

      setSelectedSite(data.site);
      setMembers(membersWithTranslatedRole);
    },
  });

  const membersFiltered = members.filter(
    (member) =>
      member.user.email?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      member.user.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      member.translatedRole?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

  const columns = [
    generateColumns("id", "ID", "left", 0, true),
    generateColumns("member", "Membro", "left", 0),
    generateColumns("email", "Email", "left", 0),
    generateColumns("permission", "Permissão", "left", 0),
    generateColumns("memberAge", "Membro desde", "left", 0),
  ];

  const createRowToDisplay = (members) => {
    const membersRows = [];

    for (const memberItem of members) {
      membersRows.push({
        id: memberItem.id,
        member: memberItem.user.name,
        email: memberItem.user.email,
        permission: memberItem.translatedRole,
        memberAge: (
          <span className="text-xl-base">
            {memberItem.user.account_status === "registered"
              ? moment(memberItem.created_at).format("DD/MM/YYYY")
              : "Pendente"}
          </span>
        ),
      });
    }

    return membersRows;
  };

  const renderSkeletonsOfMembers = (numberOfSkeletons) => {
    const skeletons = [
      <div>
        <Skeleton count={1} height={45} width={"fit-width"} />
      </div>,
    ];
    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4"
          key={`${i}-skeleton-user-drawer`}
        >
          <div>
            <Skeleton count={1} height={30} width={120} />
          </div>
          <div>
            <Skeleton count={1} height={30} width={120} />
          </div>
          <div>
            <Skeleton count={1} height={30} width={120} />
          </div>
          <div>
            <Skeleton count={1} height={30} width={120} />
          </div>
        </div>
      );
    }
    return skeletons;
  };

  const header = useContext(MainHeaderContext);
  const history = useHistory();

  useEffect(() => {
    header.title.set(
      <Breadcrumbs>
        <Crumb>Consultas</Crumb>
        <Crumb>Visualizar Local</Crumb>
      </Breadcrumbs>
    );
    const historyLocation = history.location;
    let prevPage = undefined;
    if (historyLocation.state !== undefined) {
      prevPage = historyLocation.state.prevPath;
    }
    const backRoute = prevPage !== undefined ? prevPage : "/inmaster/query-equipaments";
    header.backRoute.set(backRoute);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSearch("");
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(styles.body)}>
      <div className="row fit-width">
        <span className="col-xs-12 title-xl-base fit-width">
          VISUALIZAR LOCAL {selectedSite.name ? `- ${selectedSite.name}` : ""}
        </span>
      </div>
      {isFetchedAfterMount && members.length === 0 ? (
        <div className="d-flex justify-center fit-width">
          <EmptyMessage
            id="empty-expired-individual-record"
            title="Nenhum membro cadastrado"
            subtitle="Ainda não há membros neste local."
            minHeight="190px;"
            height="calc(100vh - 250px)"
          />
        </div>
      ) : (
        <Card
          background="var(--color-neutral-light-5)"
          className={classNames([styles.cardBody, "mt-6"])}
        >
          <div className="row">
            <div className="col-md-12">
              {isFetchedAfterMount ? (
                <span className={classNames(["title-sm-base fit-width"])}>
                  Membros ({members.length})
                </span>
              ) : (
                <Skeleton count={1} height={30} width={120} />
              )}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              {isFetchedAfterMount ? (
                <>
                  <div className="mb-2">
                    <Label>Pesquisar</Label>
                  </div>
                  <InputIconInside iconLeft={<MdSearch />}>
                    <Input
                      id="search-input"
                      value={search}
                      iconInsideLeft
                      placeholder="Pesquise pelo nome, e-mail ou permissão"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </InputIconInside>
                </>
              ) : (
                <div className="d-flex flex-column">
                  <Skeleton
                    count={1}
                    height={25}
                    width={120}
                    className="mb-2"
                  />
                  <Skeleton count={1} height={45} width={"fit-width"} />
                </div>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12 ">
              {isFetchedAfterMount ? (
                membersFiltered.length === 0 ? (
                  <ResultsNotFoundMessage
                    id="result-empty-members"
                    minHeight="100px"
                    height="calc(100vh - 300px)"
                  />
                ) : (
                  <Table
                    noSelection
                    className={classNames([styles.table])}
                    columns={columns}
                    rows={createRowToDisplay(membersFiltered)}
                  />
                )
              ) : (
                renderSkeletonsOfMembers(5)
              )}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default MembersPage;
