import React, { useEffect, useState } from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { Button } from "../Button";
import Select from "../Select";
import styles from "./Pagination.module.css";

function Pagination({
  count,
  pageIndex = 1,
  rowsPerPage,
  onPageChange = (page) => {},
  onRowsPerPageChange,
  rowsPerPageOptions = [10, 50, "Todas"],
  rowsPerPageText = "Linhas por página",
  allRowsText = "Todas",
  ofText = "de",
}) {
  const generateRowsOptions = () => {
    return rowsPerPageOptions.map((option) => {
      if (option === "Todas") {
        return {
          label: allRowsText,
          value: count,
        };
      }
      return {
        label: String(option),
        value: option,
      };
    });
  };

  const [firstRow, setFirstRow] = useState(1);
  const [lastRow, setLastRow] = useState(rowsPerPage);
  const [rowsOptions, setRowsOptions] = useState(generateRowsOptions());
  const [currentPage, setCurrentPage] = useState(pageIndex);

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    const lastPage = Math.ceil(count / rowsPerPage);
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculatePageRange = () => {
    const first = (currentPage - 1) * rowsPerPage + 1;
    const last = Math.min(count, currentPage * rowsPerPage);
    setFirstRow(first);
    setLastRow(last);
  };

  useEffect(() => {
    if (currentPage !== pageIndex) {
      onPageChange(currentPage);
    }
    calculatePageRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    setRowsOptions(generateRowsOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setFirstRow(1);
    if (rowsPerPage > count) {
      setLastRow(count);
    } else {
      setLastRow(rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <div className={styles.pagination}>
      <div className={styles.shellSelectPagination}>
        <span>{rowsPerPageText}</span>
        <Select
          id="select-pagination"
          onChange={onRowsPerPageChange}
          options={rowsOptions}
          value={rowsPerPage}
          style={{ minWidth: "80px", marginBottom: 0 }}
        />
      </div>
      <span>
        {firstRow} - {lastRow} {ofText} {count}
      </span>
      <div className={styles.shellPrevNextButton}>
        <Button
          outline
          onClick={() => previousPage()}
          disabled={currentPage === 1}
        >
          <MdArrowLeft size={28}></MdArrowLeft>
        </Button>
        <Button
          outline
          onClick={() => nextPage()}
          disabled={currentPage === Math.ceil(count / rowsPerPage)}
        >
          <MdArrowRight size={28}></MdArrowRight>
        </Button>
      </div>
    </div>
  );
}

export default Pagination;
