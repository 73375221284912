import React from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from './ItemAndDescription.module.css';

function ItemAndDescription({
  title,
  description,
  small = false,
  medium = false,
  className,
  isLoading = false
}) {
  const getTitleStyle = () => {
    if (small) {
      return 'title-sm-sm mb-1';
    } else if (medium) {
      return 'text-xl-base text-bold mb-2';
    }
    
    return 'title-sm-base mb-2';
  }

  const getDescriptionStyle = () => {
    if (small) {
      return 'text-sm-xs';
    } else if (medium) {
      return 'text-xl-base';
    }

    return 'text-xl-lg';
  }

  return (
    <div className={`${className} d-flex flex-column`}>
      <span
        id={`title-${title.replaceAll(' ', '-').toLocaleLowerCase()}`}
        className={getTitleStyle()}>
        {title}
      </span>
      {!isLoading ? (
        <span
          id={`description-${title.replaceAll(' ', '-').toLocaleLowerCase()}`}
          className={classNames([
            styles.description,
            getDescriptionStyle()
          ])}>
          {description}
        </span>
      ) : (
        <Skeleton />
      )}
    </div>
  )
}

export default ItemAndDescription;