import React, { useState } from "react";
import Modal from "../../../libs/conecte-se/ui-components/Modal";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import style from "./UpdateFirmwareModal.module.css";
import LatestCurrentFirmwareVersion from "./LatestCurrentFirmwareVersion";
import SearchFirmware from "./SearchFirmware";
import UpdateToLatestFirmwareVersionModal from "./UpdateToLatestFirmwareVersionModal";
import { useQuery } from "react-query";
import { api } from "../../../services/api/api";

const UpdateFirmwareModal = ({
  onClose,
  show,
  setSelectedFirmwareVersion,
  device,
}) => {
  const [isLatestVersion, setIsLatestVersion] = useState(true);
  const [showSearchFirmwareVersion, setShowSearchFirmwareVersion] =
    useState(false);
  const [latestFirmwareVersion, setLatestFirmwareVersion] = useState({});

  const handleSelectFirmwareVersion = (firmwareVersion) => {
    setSelectedFirmwareVersion({
      device_id: device.id,
      firmware_version: firmwareVersion,
    });
    setShowSearchFirmwareVersion(false);
    onClose();
  };

  const renderSkeletons = () => {
    return (
      <div className="d-flex justify-center align-center flex-column">
        <Skeleton
          height={135}
          width={135}
          borderRadius={100}
          className="mt-1"
        />
        <Skeleton height={20} width={400} className="mt-4 mb-7" />

        <Skeleton height={20} width={250} className="mb-10" />
      </div>
    );
  };

  const getLatestFirmwareVersion = () => {
    return api.devices.firmwares.getLastFirmware({ model: device?.model });
  };

  const { isFetching } = useQuery(
    ['lastFirmwareVersionQuery', device?.model],
    getLatestFirmwareVersion,
    {
      onSuccess: ({ data }) => {
        if (data.firmwares[0].version !== device.firmware_version) {
          setLatestFirmwareVersion(data.firmwares[0]);
          setIsLatestVersion(false);
        } else {
          setIsLatestVersion(true);
        }
      },
      enabled: show && !!device.model
    }
  );

  return (
    <Modal
      width="750px"
      show={show}
      closable
      title={"Atualizar firmware"}
      onClose={() => {
        onClose();
        setShowSearchFirmwareVersion(false);
      }}
    >
      <span className={classNames([style.subtitle])}>
        <span className="text-bold text-xl-xs">
          {"Versão de firmware atual do equipamento"}:{" "}
        </span>
        <span id={`current-firmware-version-${device?.firmware_version}`}>
          {device?.firmware_version}
        </span>
      </span>

      {isFetching ? (
        renderSkeletons()
      ) : (
        <>
          {isLatestVersion && !showSearchFirmwareVersion && (
            <LatestCurrentFirmwareVersion />
          )}

          {!isLatestVersion && !showSearchFirmwareVersion && (
            <UpdateToLatestFirmwareVersionModal
              handleSelectFirmwareVersion={handleSelectFirmwareVersion}
              latestFirmwareVersion={latestFirmwareVersion}
            />
          )}

          {showSearchFirmwareVersion && (
            <SearchFirmware
              handleSelectFirmwareVersion={handleSelectFirmwareVersion}
              device={device}
            />
          )}

          {!showSearchFirmwareVersion && (
            <div className="d-flex justify-center">
              <button
                className={classNames([style.textButton, "mb-10 text-xl-xs"])}
                onClick={() => setShowSearchFirmwareVersion(true)}
                type="button"
              >
                {"Alterar firmware para uma versão anterior"}
              </button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default UpdateFirmwareModal;
