import React from "react";
import Skeleton from "react-loading-skeleton";
import { ExpansionCard } from "../../../../libs/conecte-se/ui-components/ExpansionCard";
import ItemAndDescription from "../../ItemAndDescription";
import styles from "./RadioCard.module.css";

function RadioCard({ title, radioData, isLoading }) {
  const channelExtensionFormat = {
    Automatic: "Automática",
    Below: "Acima",
    Above: "Abaixo",
  };

  const formatChannelValue = (value) => {
    if (value === 0) {
      return "Automático";
    }

    return String(value);
  };

  return (
    <ExpansionCard
      className="mt-4"
      alwaysOpen
      header={
        !isLoading ? (
          <div className="d-flex justify-between align-center fit-width">
            <span className="title-sm-base">{title}</span>
          </div>
        ) : (
          <Skeleton width={130} height={18} />
        )
      }
    >
      <div className={styles.innerCardRadio}>
        <div>
          {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Canal:"
              description={formatChannelValue(radioData?.channel_value ?? 0)}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
          {!isLoading ? (
            <ItemAndDescription
              small
              title="Padrão IEEE 802.11:"
              description={radioData?.ieee_standard_80211 ?? ""}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
        <div>
        {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Extensão de canal:"
              description={channelExtensionFormat[radioData?.channel_extension]}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
          {!isLoading ? (
            <ItemAndDescription
              small
              title="Largura de Banda:"
              description={`${radioData?.channel_bandwidth} MHz`}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
        <div>
          {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Potência:"
              description={String(radioData?.power)}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
      </div>
    </ExpansionCard>
  );
}

export default RadioCard;
