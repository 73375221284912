import React, { useState, useEffect } from "react";
import styles from "./Tab.module.css";

const Tabs = ({ tabs = [], activeTabIndex = 0, ...props }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabs = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  useEffect(() => {
    if (activeTabIndex < 0) return;
    if (tabs[activeTabIndex].label !== activeTab) {
      setActiveTab(tabs[activeTabIndex].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  return (
    <div className={styles.Tabs}>
      <ul className={styles.nav}>
        {tabs.map((tab) => (
          <li
            key={tab.label}
            className={tab.label === activeTab ? styles.active : ""}
            onClick={() => {
              handleTabs(tab.label);
              // tab.onClick ? tab.onClick() : null;
            }}
          >
            {tab.label}
            {tab.badge}
          </li>
        ))}
      </ul>
      <div>
        {activeTab === tabs[0].label
          ? tabs[0].component
          : tabs.map((data, index) => {
              if (activeTab === data.label) {
                return (
                  <div key={data.label + String(index)} {...props}>
                    {data.component}
                  </div>
                );
              }
              return false;
            })}
      </div>
    </div>
  );
};

export default Tabs;
