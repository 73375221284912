import React from 'react'

import RoundedCheckIcon from '../icons/rounded-check'
import RoundedForbiddenIcon from '../icons/rounded-forbidden';
import Loading from '../loading/loading';

const ModalContents = {

    getSavingContent: (text) => {
        return (
            <div style={{maxWidth: '400px'}}>
                <Loading show={true}></Loading>
                {text ? text : <div>Aguarde!<br></br>Estamos salvando suas configurações.</div>}
            </div>
        )
    },

    getSuccessContent: (text) => {
        return (
            <div style={{maxWidth: '400px'}}>
                <RoundedCheckIcon></RoundedCheckIcon>
                <div>{text ? text : 'Configurações salvas com sucesso!'}</div>
            </div>
        )
    },

    getErrorContent: (text) => {
        return (
            <div style={{maxWidth: '400px'}}>
                <RoundedForbiddenIcon size={59}></RoundedForbiddenIcon>
                <div>{
                    text ? text : <div>Houve um erro ao tentar salvar suas configurações.<br></br>
                    Verifique a conexão com seu dispositivo e tente novamente.</div>
                }</div>
            </div>
        )
    },

    getRebootSucessContent: () => {
        return (
            <React.Fragment>
                <RoundedCheckIcon></RoundedCheckIcon>
                <div>O seu roteador será reiniciado. Caso esteja conectado via Wi-Fi, reconecte-se à rede após a conclusão do processo</div>
            </React.Fragment>
        )
    },

    getPPPoEErrorContent: () => {
        return (
            <React.Fragment>
                <RoundedForbiddenIcon></RoundedForbiddenIcon>
                <div>Foi detectado problema com a conexão PPPoE, verifique suas configurações.</div>
            </React.Fragment>
        )
    }
}

export default ModalContents