import classNames from "classnames";
import React, { useState } from "react";
import { MdUpload } from "react-icons/md";
import { ButtonIcon } from "../../../../libs/conecte-se/ui-components/Button";
import Card from "../../../../libs/conecte-se/ui-components/Card";
import Tag from "../../../../libs/conecte-se/ui-components/Tags";
import moment from "moment";
import styles from "./SearchFirmware.module.css";
import { useQuery } from "react-query";
import { api } from "../../../../services/api/api";
import Skeleton from 'react-loading-skeleton';

const SearchFirmware = ({ handleSelectFirmwareVersion, device }) => {
  const [firmwareVersions, setFirmwareVersions] = useState([]);

  const getFirmwareList = () => {
    return api.devices.firmwares.get({ model: device?.model });
  };

  const { isFetching } = useQuery(
    ['firmwareListQuery', device?.model],
    getFirmwareList,
    {
      onSuccess: ({ data }) => {
        const formatedFirmwares = data.firmwares.map((firmware) => ({
          ...firmware,
          collapsed: false
        }));

        setFirmwareVersions(formatedFirmwares);
      },
      enabled: !!device.model
    }
  );

  function formatChangeLog(changeLog) {
    const regex = /(\\r|\\t|\\n|»)/gm;

    const formattedChangeLog = changeLog.replace(regex, " ");
    return formattedChangeLog;
  }

  const handleCollapse = (version) => {
    const newFirmwareVersions = firmwareVersions.map((firmwareVersion) => {
      if (firmwareVersion.version === version) {
        return {
          ...firmwareVersion,
          collapsed: !firmwareVersion.collapsed,
        };
      }

      return firmwareVersion;
    });

    setFirmwareVersions(newFirmwareVersions);
  };

  const canUpdateFirmware = (version, isSupported) => {
    if (!isSupported) return false;
    if (isSupported && version !== device.firmware_version) return true;

    return false;
  };

  const rederFirmwareVersions = () => {
    return (
      <div className={classNames(["fit-width text-xl-xs", styles.cardsShell])}>
        {firmwareVersions.map((firmwareVersion) => (
          <Card
            borderColor="var(--color-brand-secondary-light)"
            className={classNames([
              "pa-1 px-4 mb-2 fit-width",
              !canUpdateFirmware(
                firmwareVersion.version,
                firmwareVersion.is_supported
              ) && "py-3",
            ])}
            key={firmwareVersion.version}
          >
            <div className="d-flex justify-between align-center">
              <div className={styles.cardInfos}>
                <div className={styles.cardItem}>
                  <span className="text-bold">{"Versão"}:</span>
                  <span
                    className="ml-2"
                    id={`number-of-version-${firmwareVersion.version}`}
                  >
                    {firmwareVersion.version}
                  </span>
                </div>
                <div className={styles.cardItem}>
                  <span className="text-bold">{"Última atualização"}: </span>
                  <span
                    className="ml-2"
                    id={`last-update-date-${firmwareVersion.updated_at}`}
                  >
                    {moment(firmwareVersion.updated_at).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div
                  id={`${firmwareVersion.version}-is-supported-${String(
                    firmwareVersion.is_supported
                  )}`}
                >
                  {firmwareVersion.is_supported ? (
                    <Tag color="green">{"suportada"}</Tag>
                  ) : (
                    <Tag color="lightblue">{"não suportada"}</Tag>
                  )}
                </div>
                <div
                  className={classNames([
                    styles.cardItem,
                    "d-flex aling-center justify-end",
                  ])}
                >
                  <button
                    className={styles.textButton}
                    type="button"
                    onClick={() => handleCollapse(firmwareVersion.version)}
                  >
                    {firmwareVersion.collapsed ? "Ver menos" : "Changelog"}
                  </button>
                  {canUpdateFirmware(
                    firmwareVersion.version,
                    firmwareVersion.is_supported
                  ) && (
                    <ButtonIcon
                      className="ml-4"
                      onClick={() =>
                        handleSelectFirmwareVersion(firmwareVersion.version)
                      }
                    >
                      <MdUpload size={24} />
                    </ButtonIcon>
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames([
                firmwareVersion.collapsed
                  ? styles.changeLogShell
                  : styles.changeLogShellClosed,
                !canUpdateFirmware(
                  firmwareVersion.version,
                  firmwareVersion.is_supported
                ) &&
                  firmwareVersion.collapsed &&
                  "mt-2",
              ])}
            >
              <span className="mb-1 text-xl-base">
                {formatChangeLog(firmwareVersion.changelog)}
              </span>
            </div>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="fit-width">
      <div
        className={classNames([
          "d-flex align-center justify-center flex-column pt-3",
          styles.cardsContainer,
        ])}
      >
        {isFetching ? (
          <div className="fit-width">
            <Skeleton height={60} borderRadius={4} className="mb-2"/>
            <Skeleton height={60} borderRadius={4} className="mb-2"/>
            <Skeleton height={60} borderRadius={4} />
          </div>
        ) : (
          rederFirmwareVersions()
        )}
      </div>
    </div>
  );
};

export default SearchFirmware;
