import React, { useEffect, useState } from 'react'

export const MenuContext = React.createContext()

const DEFAULT_MENU_WIDTH = 342

export default function MenuGlobalState({children}) {

    const [open, setOpen] = useState(false)
    const [expand, setExpand] = useState(false)
    const [fixed, setFixed] = useState(false)
    const [width, setWidth] = useState(DEFAULT_MENU_WIDTH)
    const [hideIcon, setHideIcon] = useState(false)

    const [username, setUsername] = useState('')
    const [avatar, setAvatar] = useState('')

    useEffect(() => {

        let header = document.getElementById("main-header")
        let headerTop = document.getElementsByClassName("main-header-top")[0]
        let app = document.getElementById("main-app")

        if(!app) return

        if(fixed){

            if(header)
                header.className = 'with-fixed-menu'

            if(headerTop)
                headerTop.classList.add('with-fixed-menu')

            app.className = 'with-fixed-menu'

        }else{

            if(header)
                header.className = ''

            if(headerTop)
                headerTop.classList.remove('with-fixed-menu')

            app.className = ''
            
        }

        // eslint-disable-next-line
    }, [fixed])

    useEffect(() => {

        document.documentElement.style.setProperty('--menu-width', `${width}px`)

        // eslint-disable-next-line
    }, [width])

    return <MenuContext.Provider value={{
        open: {value: open, set: setOpen},
        expand: {value: expand, set: setExpand},
        fixed: {value: fixed, set: setFixed},
        width: {value: width, set: setWidth},
        username: {value: username, set: setUsername},
        avatar: {value: avatar, set: setAvatar},
        hideIcon: {value: hideIcon, set: setHideIcon}
    }}>
        {children}
    </MenuContext.Provider>
}