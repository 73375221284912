import React, { useState } from 'react'

export const NotificationsContext = React.createContext()

export default function NotificationsState({children}) {

    const [open, setOpen] = useState(false)
    const [notifications, setNotifications] = useState([])

    return <NotificationsContext.Provider value= {{
        open: {value: open, set: setOpen},
        notifications: {value: notifications, set: setNotifications}
    }}>
        {children}
    </NotificationsContext.Provider>
}