import React from "react";
import classNames from "classnames";
import correctIcon from "../../../../assets/correct-green.svg";
import style from "./LatestCurrentFirmwareVersion.module.css";

const LatestCurrentFirmwareVersion = () => {
  return (
    <div className="d-flex align-center justify-center flex-column">
      <img
        src={correctIcon}
        alt="Ícone de confirmação"
        width="120"
        height="120"
        className="mt-1"
      />

      <span className={classNames([style.textFeedback, "mt-3 mb-7"])}>
        {"Seu equipamento está na versão de firmware mais recente"}!
      </span>
    </div>
  );
};

export default LatestCurrentFirmwareVersion;
