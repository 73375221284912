import React from 'react'

import Overlay from '../overlay/overlay'
import ModalContents from './modal-contents'

import './modal.css'
import CloseIcon from '../icons/close'
import ChevronLeftIcon from '../icons/chevron-left'

export const ModalTypes = {
    INFO: 1,
    SUCCESS: 2,
    ERROR: 3,
    CONFIRM: 4,
    LOADING: 5,
    CONFIRM_WARNING: 6
}

export default function Modal({modalID, title, content, dismissText, show, onOverlayClick, onDismissClick, type, isLarge, confirmText}){

    if(show){
        document.onkeypress = (evt) => {
            if(evt.key === 'Enter'){
                evt.preventDefault()
                if(onDismissClick)
                    onDismissClick()
                document.onkeypress = null
            }
        }
    }

    let internalContent = (() => {
        switch(type){
            case ModalTypes.SUCCESS:
                return ModalContents.getSuccessContent(content)
            case ModalTypes.ERROR:
                return ModalContents.getErrorContent(content)
            case ModalTypes.LOADING:
                return ModalContents.getSavingContent(content)
            case ModalTypes.INFO:
                return content
            default:
                return content
        }
    })()

    return(
        <div id={`${modalID ? modalID + '-' : ''}modal-container`} className={`itb-modal-container ${show ? 'show' : ''}`}>

            <Overlay open={show} onClick={onOverlayClick}></Overlay>

            <div className={`itb-modal ${isLarge ? 'large' : ''} ${title ? 'has-title' : ''}`}>

                {type === ModalTypes.CONFIRM || type === ModalTypes.CONFIRM_WARNING ?
                    <div id={`${modalID ? modalID + '-' : ''}modal-close`} className='itb-modal-close'
                    onClick={() => {
                        onDismissClick(false)
                        document.onkeypress = null
                    }}>
                    <CloseIcon size={13}></CloseIcon>
                </div> : null}

                { title ? <div className='itb-modal-title'>{title}</div> : '' }

                <div id={`${modalID ? modalID + '-' : ''}itb-modal`} className='itb-modal-content'>
                    {internalContent}
                </div>

                {
                    dismissText && type !== ModalTypes.CONFIRM && type !== ModalTypes.CONFIRM_WARNING ? <div className='itb-modal-dismiss'>
                        <div id={`${modalID ? modalID + '-' : ''}btn-dismiss`}
                            className={`dismiss-button dismiss-button-lonely ${type === ModalTypes.ERROR ? 'error' : ''}`}
                            onClick={() => {
                                onDismissClick(false)
                                document.onkeypress = null
                            }}
                        >{dismissText}</div>
                    </div> :

                    type === ModalTypes.CONFIRM || type === ModalTypes.CONFIRM_WARNING ? <div className='itb-modal-dismiss'>

                        <div className='dismiss-button dismiss-button-no' id={`${modalID ? modalID + '-' : ''}btn-no`}
                            onClick={() => {
                                onDismissClick(false)
                                document.onkeypress = null
                            }}
                        >{ dismissText ? dismissText : 'Não, voltar' }</div>

                        <div
                            className={`dismiss-button dismiss-button-yes ${type === ModalTypes.CONFIRM_WARNING ? 'warning' : ''}`}
                            id={`${modalID ? modalID + '-' : ''}btn-yes`}
                            onClick={() => {
                                if(onDismissClick)
                                    onDismissClick(true)
                                document.onkeypress = null
                            }}
                        >{confirmText ? confirmText : 'Sim, continuar'}</div>

                    </div> : ''
                }

            </div>
                <div className='itb-modal-mobile-dismiss'
                    onClick={onOverlayClick}
                ><ChevronLeftIcon size={20}></ChevronLeftIcon></div>
            </div>
        )
}