import React, { createContext, useEffect, useMemo, useState } from "react";
import { axiosInstance } from "../../services/axiosInstance";

export const TokenContext = createContext({});

export const TokenProvider = ({ children }) => {
  const [keyCloak, setKeyCloak] = useState(null);

  const contextValue = useMemo(
    () => ({
      keyCloak,
      setKeyCloak,
    }),
    [keyCloak]
  );

  useEffect(() => {
    console.log("Keycloak: ", keyCloak);
    if (!keyCloak || !keyCloak.token) return;

    const token = keyCloak.token;
    console.log("Token: ", token);

    axiosInstance.interceptors.request.use(
      (config) => {
        console.log("Token Interceptor: ", token);
        config.headers["Authorization"] = `Bearer ${token}`;

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }, [keyCloak]);

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  );
};
