import React, { useContext, useEffect } from 'react'
import ThumbIcon from '../../../components/icons/thumb-icon'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'

import './noaccess.css'

export default function NoAccessPage() {

    const header = useContext(MainHeaderContext)

    useEffect(() => {
        header.title.set('Acesso Negado')
        header.navigation.set(false)
    })

    return <div className='no-access-page'>

        <ThumbIcon size={50}></ThumbIcon>

        <div>
            Você ainda não possui acesso ao sistema.
            <br></br>
            Solicite o acesso para um administrador.
        </div>

    </div>
}