import React from 'react'

import './overlay.css'

export default function Overlay({open, onClick}) {

    return <div className={`overlay ${open ? 'show' : ''}`} onClick={() => {
        if(onClick)
            onClick()
    }}></div>
}