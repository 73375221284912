import React from 'react'

export default function ChevronDownIcon({size}) {

    if(!size)
        size = "32"

    return(
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14L16 18 20 14" transform="translate(-289.000000, -306.000000) translate(0.000000, 282.000000) translate(305.000000, 40.000000) scale(1, -1) translate(-305.000000, -40.000000) translate(289.000000, 24.000000) translate(16.000000, 16.000000) scale(1, -1) translate(-16.000000, -16.000000)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}