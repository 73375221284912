import React, { useEffect, useState } from 'react'

import Loading from '../components/loading/loading'
import KeycloakJS from 'keycloak-js'

const TOKEN_UPDATE_TIME = 20000

export const DISABLE_AUTH = process.env.REACT_APP_DISABLE_AUTH || false

export const KeycloakContext = React.createContext()

export default function Keycloak({children}) {

    const [kc, setKc] = useState({})
    const [authenticating, setAuthenticating] = useState(true)

    useEffect(() => {

        if(DISABLE_AUTH) return

        console.log(process.env.REACT_APP_KEYCLOAK_ADDRESS)

        setAuthenticating(true)

        let keycloak = KeycloakJS({
            url: `${process.env.REACT_APP_KEYCLOAK_ADDRESS}/auth`,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
            realm: process.env.REACT_APP_KEYCLOAK_REALM
        })

        keycloak.onAuthError = (error) => {
            console.log('Error autenticating')

            alert(error)
        }

        let updateInterval = null


        keycloak.init({onLoad:'login-required'}).then((authenticated) => {

            console.log('autenticado', authenticated)

            localStorage.setItem('auth', 'true')

            setAuthenticating(false)

            updateInterval = setInterval(() => {
                keycloak.updateToken(30).error((error) => console.log('Erro ao atualizar o token:', error))
            }, TOKEN_UPDATE_TIME)
        })


        // backend.setAuthInfo(keycloak)

        setKc(keycloak)
        // eslint-disable-next-line

        return () => {
            clearInterval(updateInterval)
        }

        // eslint-disable-next-line
    }, [])

    if(DISABLE_AUTH)
        return children

    return <KeycloakContext.Provider value={kc}>
                {authenticating ? <Loading show={true}></Loading> : children}
           </KeycloakContext.Provider>

    // return children
}
