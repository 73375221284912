import { axiosInstance } from "../../axiosInstance";

export class DeviceApi {
  urlUsers = "/users";

  urlBase = (placeId) => `/places/${placeId}/devices`;

  getByEmail(params = {}) {
    return axiosInstance.get(`${this.urlUsers}/find-email/devices`, { params });
  }

  getByMac(params = {}) {
    return axiosInstance.get(`${this.urlUsers}/find-mac/devices`, { params });
  }

  reboot(data) {
    return axiosInstance.post(
      `${this.urlBase(data.placeId)}/${data.deviceId}/reboot`
    );
  }

  deleteList(data) {
    const urlString = `${this.urlBase(data.placeId)}/${data.deviceId}}/delete`;
    return axiosInstance.delete(urlString);
  }

  details = {
    get: (data) => {
      return axiosInstance.get(
        `${this.urlBase(data.placeId)}/${data.deviceId}`
      );
    },
  };

  firmwares = {
    getLastFirmware: (data) => {
      return axiosInstance.get(`/firmwares`, {
        params: {
          device_model: data.model,
          only_latest: true,
        },
      });
    },
    update: (data) => {
      return axiosInstance.put(`${this.urlBase(data.placeId)}/${data.deviceId}/firmwares`, {
        firmware_version: data.firmwareVersion,
      });
    },
    get: (data) => {
      return axiosInstance.get(`/firmwares`, {
        params: {
          device_model: data.model,
          only_latest: false,
        },
      });
    }
  };
}
