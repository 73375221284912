import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Form from '../../../components/form/form'
import Input from '../../../components/input/input'
import Loading from '../../../components/loading/loading'
import InfoLine from '../../../components/info-line/info-line'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'

import './users.css'
import Select from '../../../components/select/select'
import DefaultStatusModals from '../../../components/modal/default-status-modals'
import CnpjUtils from '../cpnj-utils'
import CnpjValidator from '../../../components/form/validators/cnpj'
import PhoneUtils from '../phone-utils'
import Phonevalidator from '../../../components/form/validators/phone'
import { useParams } from 'react-router'

export const RemotizeUserTypeOptions = [
    {value: 0, text:'Bloqueado'},
    {value: 1, text:'Administrador'},
    {value: 2, text:'Técnico'},
]

export const ispForm = (isp, setISP, saveIsp) => {

    const setCNPJ = (value) => {
        if(isNaN(value)) return
        setISP({...isp, cnpj: value})
    }

    const setPhone = (e) => {
        if(!PhoneUtils.isValidValue(e)) return
        setISP({...isp, phone: e.target.value})
    }

    if(!isp) return null

    return <div className='section'>
        <div className='section-card user-form'>

            <div className='subtitle'>Dados do provedor</div>

            <Form id='isp' onSubmit={saveIsp}>

                <Input id='isp-name'
                    label='Nome do provedor'
                    value={isp.name}
                    onChange={e => setISP({...isp, name: e.target.value})}
                ></Input>

                <Input id='isp-cnpj'
                    label='CNPJ do provedor'
                    value={isp.cnpj}
                    onChange={e => setCNPJ(e.target.value)}
                    onBlur={() => setISP({...isp, cnpj: CnpjUtils.applyMask(isp.cnpj)})}
                    onFocus={() => setISP({...isp, cnpj: CnpjUtils.removeMask(isp.cnpj)})}
                    validators={[CnpjValidator.cnpj]}
                ></Input>

                <Input id='isp-phone'
                    label='Telefone do provedor'
                    value={isp.phone}
                    onChange={e => setPhone(e)}
                    onBlur={() => setISP({...isp, phone: PhoneUtils.applyMask(isp.phone)})}
                    onFocus={() => setISP({...isp, phone: PhoneUtils.removeMask(isp.phone)})}
                    validators={[Phonevalidator.phone]}
                ></Input>

                <InfoLine label="Quantidade de dispositivos" value={isp.devices}></InfoLine>
            </Form>
        </div>
    </div>
}

export default function UsersPage() {

    const [search, setSearch] = useState('')
    const [user, setUser] = useState(null)
    const [isp, setISP] = useState(null)
    const [pristine, setPristine] = useState(true)
    const [loading, setLoading] = useState(false)

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const params = useParams()

    useEffect(() => {

        header.title.set("Usuários")
        header.navigation.set(true)

        if(params.id)
            searchUser(params.id)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(user && user.ispid !== "" && !isp)
            searchISP()

            // eslint-disable-next-line
    }, [user])

    const searchUser = async(id) => {

        setUser(null)
        setISP(null)

        setLoading(true)

        let result = await backend.retrieveFresh(`users/${id}`)
        if(result.status === backendStatus.SUCCESS){
            setUser(result.content)
        }


        setLoading(false)
        setPristine(false)
    }

    const searchISP = async() => {

        setLoading(true)

        let result = await backend.retrieveFresh(`isps/${user.ispid}`)
        if(result.status === backendStatus.SUCCESS){
            setISP(result.content)
        }

        setLoading(false)
        setPristine(false)
    }

    const saveUser = async() => {
        sendSave('users', user)
    }

    const saveIsp = async() => {
        sendSave('isps', isp)
    }

    const sendSave = async(route, data) => {

        setSaving(true)

        let result = await backend.update(route, data)

        setSaving(false)

        if(result.status !== backendStatus.SUCCESS){
            setError(true)
            return
        }

        setSuccess(true)

    }


    const userForm = () => {

        if(!user) return null

        return <div className='section'>
            <div className='section-card user-form'>
                <div className='subtitle'>Conta do usuário</div>

                <Form id='user-form' onSubmit={saveUser}>
                    <Select id='user-type'
                        label='Tipo do usuário'
                        options={RemotizeUserTypeOptions}
                        value={user.type}
                        onChange={e => setUser({...user, type: Number(e.target.value)})}
                    ></Select>
                    <InfoLine label='Nome' value={user.name}></InfoLine>
                    <InfoLine label='Email' value={user.email}></InfoLine>
                    <InfoLine label='Identificador' value={user.username}></InfoLine>
                    <InfoLine label='Provedor do ID' value={user.idprovider}></InfoLine>
                </Form>

            </div>
        </div>
    }

    const closeModal = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    return <div className='users-page'>

        <DefaultStatusModals
            saving={saving}
            success={success}
            error={error}
            continueFn={closeModal}
        ></DefaultStatusModals>

        <Form onSubmit={() => searchUser(search)} id='users-search' submitText='Buscar' buttonId='search-button'>
            <Input id='search-input'
                value={search}
                onChange={e => {
                    setSearch(e.target.value)
                }}
                placeholder='Email ou identificador'
            ></Input>
        </Form>

        {loading && <Loading show={true}></Loading>}

        {!pristine && !user && <div className='no-users-info'>Nenhum usuário encontrado</div>}

        <div className='flex-sections-container'>

            {userForm()}

            {ispForm(isp, setISP, saveIsp)}

        </div>
    </div>
}