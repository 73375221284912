const PhoneUtils = {

    isValidValue: (e) => {

        let value = e.target.value 

        for(let c of value)
            if(isNaN(c) && c !== '('  && c !== ')' && c !== '-' && c !== ' ')
                return false

        return true
    },

    applyMask: (phone) => {

        phone = phone.replace(/\(|\)| |-/gi, '')

        let masked = ''

        for(let i in phone){
            if(i === '0')
                masked += '('

            masked += phone[i]

            if(i === '1')
                masked += ') '
            if(phone.length === 10 && i === '5')
                masked += '-'
            if(phone.length === 11 && i === '6')
                masked += '-'
        }

        return masked
    },

    removeMask: (phone) => {

        return phone.replace(/\(|\)| |-/gi, '')
    }

}

export default PhoneUtils
