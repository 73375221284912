import React from 'react'
import HelpIcon from '../icons/help-icon'
import TooltipText from '../tooltip-text/tooltip-text'

import './info-popup.css'

export default function InfoPopup({message}) {

    return <div className='info-popup'>
        <TooltipText message={message}></TooltipText>
        <HelpIcon size={20}></HelpIcon>
    </div>
}