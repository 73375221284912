import React, { useState } from 'react'
import EyeIcon from '../../components/icons/eye-icon'
import EyeOffIcon from '../../components/icons/eye-off-icon'

import './info-line.css'

export default function InfoLine({label, value}){

    return(
        <div className='info-line'>
            <label>{label}:</label>
            <span>{value}</span>
        </div>
    )
}

export function InfoLinePass({label, value, name}){

    const [OpenEyeState, setOpenEyeState] = useState(false)

    const getPasswordIcons = () => {

        let typeClass = (OpenEyeState ? '' : 'eye-off')
        return (
            <div id={`itb-icon-${name}`}
                className={`itb-password-icon clickable ${typeClass}`}
                onClick={() => {
                    setOpenEyeState(OpenEyeState ? false : true)
                }}
            >
                {OpenEyeState ?  <EyeIcon></EyeIcon> : <EyeOffIcon></EyeOffIcon>}
            </div>
        )
    }

    const hideValue  = () => {

        let hiddenValeu = ''

        for (let index = 0; index < value.length; index++) {
            hiddenValeu= hiddenValeu+'●'
        }
        return hiddenValeu
    }

    return(
        <div className='info-line'>
            <label>{label}:</label>
            <span value={value} type={!OpenEyeState ? "password" : "text" }>{OpenEyeState ? value : hideValue() }</span>
            {getPasswordIcons()}
        </div>
    )
}