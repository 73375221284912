import React, { useContext, useEffect } from 'react'

import './menu.css'
import { MenuContext } from './menu-state';
import { MainHeaderContext } from '../main-header/main-header-state'
import Overlay from '../overlay/overlay';
import intelbrasLogo from './logo-intelbras.png'
import menuIcon from '../main-header/ic-menu.svg'

export default function Menu({children, fixed, headerMessage = null, customLogo = null, isWebView = false}) {

    let state = useContext(MenuContext)
    let header = useContext(MainHeaderContext)

    useEffect(() => {

        if(!header.navigation.value) {
            state.fixed.set(false)
            state.open.set(false)
        }

        // eslint-disable-next-line
    }, [header.navigation])


    return (
        <React.Fragment>
            {!fixed && <Overlay open={state.open.value} onClick={() => state.open.set(false)}></Overlay>}
            {header.navigation.value && <div id='menu-container' className={`${state.open.value || state.fixed.value ? 'open' : ''}${state.fixed.value ? ' fixed' : ''}`}>

                <div className='menu-content'>

                    {!isWebView ? null : <button id='menu-button-inside' className='clickable' onClick={ ()=> state.open.set(!state.open.value) } >
                        <img alt='Menu' src={menuIcon}></img>
                    </button>}

                    <div className='menu-logo'><img alt='' src={customLogo ? customLogo : intelbrasLogo}></img></div>
                    <div>
                        {headerMessage}
                    </div>

                    <div id='menu-area' className='menu-entries'>
                        {children}
                    </div>
                </div>
            </div>}
        </React.Fragment>
    )
}