import { RemotizeUserTypeOptions } from "../users/users-page";

export const oplogTypes = {
	REMOTIZE_USER_LEVEL: 0,
	REMOTIZE_ISP_DATA: 1,
	REMOTIZE_DEVICE_PROFILE_REAPLY: 2,
	REMOTIZE_DEVICE_DESASSOCIATION: 3,
}

export default function(oplog) {

    switch (oplog.type) {
        case oplogTypes.REMOTIZE_USER_LEVEL:
            return `Alterou o nível de acesso do usário ${oplog.from["email"]} para ${RemotizeUserTypeOptions[oplog.to.type].text}`
        case oplogTypes.REMOTIZE_ISP_DATA:
            return `Alterou os dados do provedor ${oplog.from["cnpj"]}`
        case oplogTypes.REMOTIZE_DEVICE_PROFILE_REAPLY:
            return `Programou a reaplicação do perfil do dispositivo ${oplog.from["deviceid"]}`
        case oplogTypes.REMOTIZE_DEVICE_DESASSOCIATION:
            return `Realizou a desassociação do dispositivo ${oplog.from["deviceid"]} do provedor ${oplog.from["cnpj"]}`
        default:
            return 'Operação desconhecida'
    }

}