import React from 'react';
import Checkbox from '../Checkbox';
import styles from './Table.module.css';
import _ from 'lodash';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import Loading from '../Loading';
import { MdWarning } from 'react-icons/md';
function Table({
  columns = [
    {
      field: '',
      headerName: '',
      align: '', // center || left || right
      width: 0
    }
  ],
  rows = [],
  onSelectRows = (selectedRows) => {},
  selectedRows = [],
  keyToCompare = 'id',
  blockFirstItem = false,
  disableSelection = false,
  elementToInactiveRow = '',
  elementToDisableRow = '',
  textTooltipDisableRow = '',
  className = '',
  noSelection = false,
  useKeyToCompareAsId = false,
  hasStatus = false,
  ...props
}) {
  const selectRow = (row) => {
    if (selectedRows.some((item) => item[keyToCompare] === row[keyToCompare])) {
      return onSelectRows(
        selectedRows.filter((item) => item[keyToCompare] !== row[keyToCompare])
      );
    }
    return onSelectRows([...selectedRows, row]);
  };

  const areArraysEqual = (arrayA, arrayB) => {
    let auxA = [];
    let auxB = [];
    if (arrayA.length) {
      auxA = _.orderBy(arrayA, keyToCompare);
    }
    if (arrayB.length) {
      auxB = _.orderBy(arrayB, keyToCompare);
    }
    return _.isEqual(
      auxA.map((item) => item[keyToCompare]),
      auxB.map((item) => item[keyToCompare])
    );
  };

  const selectAllRows = () => {
    let auxRows = [...rows];
    if (blockFirstItem) {
      auxRows = [...rows].slice(1);
    }
    auxRows = auxRows.filter(
      (row) => elementToDisableRow === '' || !row[elementToDisableRow]
    );
    if (areArraysEqual(auxRows, selectedRows)) {
      return onSelectRows([]);
    }
    onSelectRows(auxRows);
  };

  const isFullChecked = () => {
    if (blockFirstItem) {
      if (rows.slice(1).length === 0 && selectedRows.length === 0) {
        return false;
      }
    }
    if (rows.length === 0 && selectedRows.length === 0) {
      return false;
    }
    return areArraysEqual(
      [...rows]
        .slice(blockFirstItem ? 1 : 0)
        .filter(
          (row) => elementToDisableRow === '' || !row[elementToDisableRow]
        ),
      selectedRows
    );
  };

  const iconStatusOptions = {
    loading: <Loading value={40} indeterminate show />,
    warning: <MdWarning color="var(--color-status-alert-base)" size={20} />
  };

  return (
    <table className={classNames([styles.table, className])} {...props}>
      <thead>
        <tr>
          {hasStatus ? <th></th> : null}
          {noSelection ? null : (
            <th>
              <Checkbox
                checked={isFullChecked()}
                onChange={() => selectAllRows()}
                color="var(--color-brand-secondary-light)"
                backgroundActive="var(--color-neutral-light-5)"
                indeterminate={
                  selectedRows.length >= 1 &&
                  !areArraysEqual(
                    rows
                      .slice(blockFirstItem ? 1 : 0)
                      .filter(
                        (row) =>
                          elementToDisableRow === '' ||
                          !row[elementToDisableRow]
                      ),
                    selectedRows
                  )
                }
                disabled={disableSelection}
                id="check-all"
              />
            </th>
          )}
          {columns.map((column) =>
            !column.invisible ? (
              <th key={column.field} name={column.field}>
                <div
                  className={classNames([
                    styles.headerTitle,
                    column.align
                      ? styles[`align${column.align.toUpperCase()}`]
                      : null
                  ])}>
                  {column.headerName}
                </div>
              </th>
            ) : null
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={
              keyToCompare
                ? row[keyToCompare]
                : `${Object.values(row)[0]}-${Object.values(row)[1]}`
            }
            className={
              selectedRows.some(
                (item) => item[keyToCompare] === row[keyToCompare]
              )
                ? styles.selectedRow
                : null
            }>
            {hasStatus ? (
              <td
                className={classNames([
                  styles.statusContent,
                  row.onStatusClick && styles.statusClickable
                ])}>
                <Tooltip
                  toolTipStyle={{ width: 205 }}
                  text={row.statusText}
                  disabled={!row.statusText}
                  positionX="start"
                  onClick={row.onStatusClick}>
                  {iconStatusOptions[row.status]}
                </Tooltip>
              </td>
            ) : null}
            {noSelection ? null : (
              <td
                name="check"
                id={
                  useKeyToCompareAsId ? `checkbox@${row[keyToCompare]}` : null
                }>
                <Tooltip
                  text={textTooltipDisableRow}
                  id="tooltip-disabled-wireless"
                  positionX="start"
                  disabled={
                    elementToDisableRow === '' ||
                    !row[elementToDisableRow] ||
                    textTooltipDisableRow === ''
                  }>
                  <Checkbox
                    checked={selectedRows.some(
                      (item) => item[keyToCompare] === row[keyToCompare]
                    )}
                    onChange={() => selectRow(row)}
                    disabled={
                      disableSelection ||
                      (blockFirstItem && index === 0) ||
                      (row[elementToDisableRow] && elementToDisableRow !== '')
                    }
                    id={
                      useKeyToCompareAsId ? `check@${row[keyToCompare]}` : null
                    }
                  />
                </Tooltip>
              </td>
            )}
            {columns.map((column, index) =>
              !column.invisible ? (
                <td
                  name={column.field}
                  key={Object.values(row)[0] + index}
                  className={
                    (elementToInactiveRow === '' ||
                      !row[elementToInactiveRow]) &&
                    (elementToDisableRow === '' || !row[elementToDisableRow])
                      ? null
                      : styles.inactive
                  }>
                  <div
                    id={
                      useKeyToCompareAsId
                        ? `${column.field}@${row[keyToCompare]}`
                        : column.field
                    }
                    className={
                      column?.align
                        ? styles[`align${column?.align?.toUpperCase()}`]
                        : null
                    }>
                    {row[column.field]}
                  </div>
                </td>
              ) : null
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
