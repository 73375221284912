import React, { useContext, useState, useEffect } from 'react'

import '../input/input.css'
import './select.css'
import { FormContext } from '../form/form'

export default function Select({id, value, options, label, onChange, errorMessage, validators, name, clearErrors = false}){

    const formContext = useContext(FormContext)
    const [error, setError] = useState({active:false, message:''})

    const externalError = errorMessage || ''

    useEffect(() => {
        if(formContext && validators) {
            formContext.setValidators(name, runValidators, value)
        }

        return () => {
            if(formContext && validators)
                formContext.removeValidators(name)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    async function runValidators(value) {

        let noErrors = true

        setError({active: false, message: ''})
        if(formContext)
            formContext.subError(name)
                    
        if(validators){
            for(let validator of validators) {

                let message = ''
                if(typeof(validator) === 'object')
                    message = await validator.fn(value, validator.params)
                else
                    message = await validator(value)

                if(message !== ''){

                    noErrors = false
                    setError({active: true, message: message})

                    if(formContext)
                        formContext.addError(name)

                    break;
                }
            }
        }


        return noErrors
    }

    return(
    <div className={`itb-input-field select ${(error && error.active) ? 'error' : ''}`}>

        <label>{label}</label>

        <select className='itb-input' id={id}
            value={value}
            onChange={(e) => {
                if(formContext) {
                    formContext.setPristine(false)

                    if (clearErrors)
                        formContext.clearErrors()
                }
                if(validators)
                    runValidators(e.target.value)

                onChange(e)
            }}
        >
            {
                options.map((option, key) => {

                    return(
                        <option key={key} id={`${id}-${key}`} value={option.value}>
                            {option.text}
                        </option>
                    )
                })
            }
        </select>
        <span id={`${id}-error`} className='itb-input-error-text'>
            {(error && error.active) || externalError !== '' ? error.message || externalError : ''}
        </span>
    </div>)

}