import React from 'react';
import classNames from 'classnames';
import { MdAdd, MdSentimentNeutral } from 'react-icons/md';
import { Button, ButtonTextOutside } from '../../libs/conecte-se/ui-components/Button';
import styles from './EmptyMessage.module.css';

const EmptyMessage = ({
  title,
  subtitle,
  id,
  buttonText,
  disabled,
  action,
  height,
  minHeight,
  icon,
  ...props
}) => {
  return (
      <div className="row" {...props} style={{ height, minHeight }}>
        <div
          className={classNames([
            styles.bodyMessage,
            height ? null : styles.fullPage,
            "col-12"
          ])}>
          <div
            className={classNames(['d-flex justify-center align-center', styles.content])}>
            <div
              className={classNames([
                'd-flex justify-center align-center',
                styles.ball
              ])}>
              <span
                className={classNames([
                  'd-flex justify-center',
                  styles.iconInBall
                ])}>
                {icon ? icon : <MdSentimentNeutral size={33} /> }
              </span>
            </div>

            <div
              className={classNames(['d-flex flex-column align-start ml-4'])}>
              <h1 className="title-xl-lg">{title}</h1>
              <span className={classNames(['mt-1'])}>{subtitle}</span>
              {buttonText && (
                <ButtonTextOutside
                  value={buttonText}
                  className="mt-3 text-uppercase">
                  <Button
                    disabled={disabled}
                    id={`button-${id}`}
                    rounded
                    onClick={() => action && action()}>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

EmptyMessage.defaultProps = {
  action: null,
  disabled: false,
  buttonText: undefined,
  height: null,
  minHeight: null
};

export { EmptyMessage };
