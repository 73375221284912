import React from 'react'

export default function EyeOffIcon(){

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#92A4B1" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path d="M2 4.27L3.28 3 20 19.72 18.73 21l-3.08-3.08c-1.15.38-2.37.58-3.65.58-5 0-9.27-3.11-11-7.5.69-1.76 1.79-3.31 3.19-4.54L2 4.27M12 8c1.657 0 3 1.343 3 3 0 .35-.06.69-.17 1L11 8.17c.31-.11.65-.17 1-.17m0-4.5c5 0 9.27 3.11 11 7.5-.82 2.08-2.21 3.88-4 5.19l-1.42-1.43c1.36-.94 2.48-2.22 3.24-3.76-1.65-3.36-5.06-5.5-8.82-5.5-1.09 0-2.16.18-3.16.5L7.3 4.47c1.44-.62 3.03-.97 4.7-.97M3.18 11c1.65 3.36 5.06 5.5 8.82 5.5.69 0 1.37-.07 2-.21L11.72 14c-1.43-.15-2.57-1.29-2.72-2.72L5.6 7.87c-.99.85-1.82 1.91-2.42 3.13z" transform="translate(-1404.000000, -292.000000) translate(495.000000, 256.000000) translate(909.000000, 36.000000)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
} 