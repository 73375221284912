import React, { useState } from 'react'

import './button.css'

export default function Button(props) {

    const [circular,] = useState(props.circular ? true : false)
    const [isError,] = useState(props.isError ? true : false)
    const [outlined,] = useState(props.outlined ? true : false)

    var getClasses = () => {
        let classes = 'itb-button itb-button-md'
        if(circular){
            classes += ' itb-button-circular'
        }
        if(isError){
            classes += ' itb-button-red'
        }
        if(outlined){
            classes += ' outlined'
        }

        return classes
    }

    let propsCopy = {...props}
    delete propsCopy.circular
    delete propsCopy.isError
    delete propsCopy.outlined

    return (
        <div className='itb-button-container'>
            <button {...propsCopy} className={getClasses()} disabled={props.disabled || props.loading}>
                {props.loading ? <i className='itb-button-loading'></i> : props.text}
            </button>
        </div>
    )
}