/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Loading.module.css";
import ResponseMessage from "../ResponseMessage";
import Card from "../Card";

const Loading = ({
  value = 0,
  message = "",
  type = "",
  showPercentage = false,
  fullscreen = false,
  indeterminate = false,
  colors = {},
  show = false,
  width = 24,
  height = 24,
  radius = 7.5,
  id = "",
  className = "",
  ...props
}) => {
  const [circumference, setCircumference] = useState(0);
  const [offset, setOffset] = useState(0);
  const [offsetInside, setOffsetInside] = useState(0);

  useEffect(() => {
    setCircumference(radius * 2 * Math.PI);
    setOffset(circumference - (100 / 112) * circumference);
    setOffsetInside(circumference - (value / 112) * circumference);
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [value, circumference, radius]);

  useEffect(() => {
    if (show && fullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const renderLoading = () => {
    return (
      <div
        {...props}
        id={id}
        className={classNames([styles.containerFlex, className])}
      >
        <svg width={width} height={height}>
          <circle
            className={classNames([
              styles.circle,
              indeterminate ? styles.indeterminate : "",
            ])}
            fill="transparent"
            stroke="white"
            strokeWidth="5"
            r={radius}
            cx={width / 2}
            cy={height / 2}
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: offset,
              stroke: colors.outside || "var(--loading-bg)",
            }}
          />
          <circle
            className={classNames([
              styles.circleInside,
              indeterminate ? styles.indeterminateInside : "",
            ])}
            fill="transparent"
            stroke="white"
            strokeWidth="5"
            r={radius}
            cx={width / 2}
            cy={height / 2}
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: offsetInside,
              stroke: colors.inside || "var(--loading-inside-bg)",
            }}
          />
        </svg>
        {message ? (
          <ResponseMessage className="ml-1">
            {showPercentage ? `${message} (${value}%)` : message}
          </ResponseMessage>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {!fullscreen ? (
        <>
          {!type ? (
            renderLoading()
          ) : (
            <ResponseMessage status={type} id={id}>
              {showPercentage ? `${message} (${value}%)` : message}
            </ResponseMessage>
          )}
        </>
      ) : (
        <>
          {show ? (
            <>
              <div className={styles.progressCircularShell}>
                <Card>{renderLoading()}</Card>
              </div>
              <div className={styles.progressCircularFullscreen} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default Loading;
