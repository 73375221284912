import React from "react";
import "./midgard-app.css";
import MainHeader from "./components/main-header/main-header";
import { HashRouter as Router } from "react-router-dom";
import MainHeaderGlobalState from "./components/main-header/main-header-state";
import MenuGlobalState from "./components/menu/menu-state";
import MainApp from "./app/main-app";
import Backend from "./backend/backend";
import NotificationsState from "./components/notifications/notifications-state";
import Keycloak from "./app/keycloak";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastProvider } from "./app/inmaster/contexts/ToastContext";
import { TokenProvider } from "./app/inmaster/contexts/TokenContext";

function setViewPortHeightUnit() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

setViewPortHeightUnit();

window.addEventListener("resize", () => {
  setViewPortHeightUnit();
});

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <div className="midgard-app">
      <Keycloak>
        <MainHeaderGlobalState>
          <NotificationsState>
            <MenuGlobalState>
              <Router>
                <Backend>
                  <TokenProvider>
                    <QueryClientProvider client={queryClient}>
                      <ToastProvider>
                        <MainHeader></MainHeader>

                        <MainApp></MainApp>
                      </ToastProvider>
                    </QueryClientProvider>
                  </TokenProvider>
                </Backend>
              </Router>
            </MenuGlobalState>
          </NotificationsState>
        </MainHeaderGlobalState>
      </Keycloak>
    </div>
  );
}

export default App;
