import React from 'react'

export default function RoundedForbiddenIcon({size}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height="58" viewBox="0 0 58 58">
            <g fill="#D72735" fillRule="nonzero">
                <path d="M29 58C12.984 58 0 45.016 0 29S12.984 0 29 0s29 12.984 29 29c-.02 16.008-12.992 28.98-29 29zm0-56.168A27.193 27.193 0 1 0 56.193 29C56.176 13.989 44.01 1.824 29 1.807v.025z"/>
                <path d="M48.524 49a1.62 1.62 0 0 1-1.094-.408L8.59 12.544c-.473-.343-.686-.91-.547-1.45.139-.542.605-.958 1.193-1.067.588-.108 1.19.112 1.541.563l38.842 36.048c.39.412.491.997.257 1.502-.233.505-.76.84-1.351.86"/>
            </g>
        </svg>
    )
}