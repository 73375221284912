import { isNull } from "lodash";
import React from "react";
import { useToast } from "../../../hooks/useToast";
import Modal from "../../../libs/conecte-se/ui-components/Modal";
import { api } from "../../../services/api/api";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DeleteDeviceModal = ({
  selectedDevice,
  show,
  onClose,
  onCancel,
  setDeviceIsDeleting,
}) => {
  const { addToast } = useToast();
  const history = useHistory();

  const deleteDeviceMutation = useMutation(
    (data) => {
      return api.devices.deleteList(data);
    },
    {
      onSuccess: () => {
        addToast("success", "Equipamento excluído com sucesso");

        onClose();

        const historyState = history.location;

        if (historyState.state === undefined) {
          history.push("/inmaster/query-equipaments");
        } else {
          history.push(historyState.state.prevPath);
        }
      },
      onError: (error) => {
        if (error.response.status === 500) {
          addToast(
            "error",
            "Estamos passando por uma instabilidade, tente novamente mais tarde"
          );
        }

        onClose();
      },
      retry: 0,
    }
  );

  const deleteDevices = () => {
    if (!isNull(setDeviceIsDeleting) && setDeviceIsDeleting) {
      setDeviceIsDeleting(true);
    }
    deleteDeviceMutation.mutate({
      placeId: selectedDevice.place_id,
      deviceId: selectedDevice.id,
    });
  };

  const modalDeleteActions = [
    {
      label: "Cancelar",
      action: () => onCancel(),
      disabled: deleteDeviceMutation.isLoading,
    },
    {
      label: "Excluir",
      action: deleteDevices,
      isLoading: deleteDeviceMutation.isLoading,
    },
  ];

  const messagesToDisplayOnModalSingular = {
    AP: "O equipamento será removido da sua conta e voltará ao padrão de fábrica. Deseja realmente excluir?",
    CFTV: "O equipamento será removido da sua conta. Deseja realmente excluir?",
  };

  return (
    <Modal width="500px" show={show} actions={modalDeleteActions}>
      {messagesToDisplayOnModalSingular[selectedDevice?.type_equipment]}
    </Modal>
  );
};

DeleteDeviceModal.defaultProps = {
  setDeviceIsDeleting: null,
};

export default DeleteDeviceModal;
