import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../backend/backend'
import { useHistory } from 'react-router'
import Loading from '../components/loading/loading'

const UserTypes = {
    NONE: 0,
    COMMON: 1,
    ADMIN: 2
}

export const UserContext = React.createContext()

export default function UserContextComp({children}) {

    const [user, setUser] = useState(null)

    const backend = useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {

        if(!backend) return

        fetchUser()

        // eslint-disable-next-line
    }, [backend])

    const fetchUser = async() => {

        let result = await backend.retrieveFresh("/hdusers/current")

        if(result.status !== backendStatus.SUCCESS){
            return
        }

        let user = result.content

        setUser(user)

        if(user.type === UserTypes.NONE){
            history.push("/noaccess")
        }else{

            if(history.location.pathname === "/noaccess")
                history.push("/")
        }
    }

    if(!user)
        return <Loading show={true}></Loading>

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>

}