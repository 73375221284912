import React from 'react'

import Modal from './modal'
import { ModalTypes } from './modal'

const DefaultStatusModals = ({modalID, saving, success, error, continueFn, savingText, successText, errorText,
    confirm, confirmContent, confirmText, dismissText, info, infoText, isWarningModal = false}) =>
{

    return(
    <React.Fragment>

        <Modal
            modalID={`${modalID ? modalID + '-' : ''}loading`}
            show={saving}
            content={savingText}
            type={ModalTypes.LOADING}
        ></Modal>

        <Modal
            modalID={`${modalID ? modalID + '-' : ''}success`}
            show={success}
            content={successText} 
            dismissText='Ok, entendi'
            onDismissClick={continueFn}
            type={ModalTypes.SUCCESS}
        ></Modal>

        <Modal
            modalID={`${modalID ? modalID + '-' : ''}error`}
            show={error}
            content={errorText} 
            dismissText='Ok, entendi'
            onDismissClick={continueFn}
            type={ModalTypes.ERROR}
        ></Modal>

        <Modal
            modalID={`${modalID ? modalID + '-' : ''}confirm`}
            show={confirm}
            isConfirm={true}
            content={confirmContent}
            dismissText={dismissText}
            confirmText={confirmText}
            onDismissClick={continueFn}
            type={isWarningModal ? ModalTypes.CONFIRM_WARNING : ModalTypes.CONFIRM}
        ></Modal>

        <Modal
            modalID={`${modalID ? modalID + '-' : ''}info`}
            show={info}
            content={infoText}
            dismissText='Ok, entendi'
            onDismissClick={continueFn}
            type={ModalTypes.INFO}
        ></Modal>

    </React.Fragment>
    )
}

export default DefaultStatusModals