import React from "react";
import Modal from "../../../libs/conecte-se/ui-components/Modal";
import { api } from "../../../services/api/api";
import { useMutation } from "react-query";

const ConfirmUpdateModal = ({
  onClose,
  show,
  equipamentName,
  selectedFirmwareVersion,
  onSucess,
  placeId
}) => {
  const updateFirmwareMutation = useMutation(
    () =>
      api.devices.firmwares.update({
        deviceId: selectedFirmwareVersion.device_id,
        firmwareVersion: selectedFirmwareVersion.firmware_version,
        placeId
      }),
    {
      onSuccess: () => {
        onSucess();
      }
    }
  );

  const handleUpdate = () => {
    updateFirmwareMutation.mutate();
  };

  const updateActions = [
    {
      label: "CANCELAR",
      disabled: updateFirmwareMutation.isLoading,
      action: () => {
        onClose();
      },
    },
    {
      label: "CONTINUAR",
      isLoading: updateFirmwareMutation.isLoading,
      action: () => {
        handleUpdate();
      },
    },
  ];

  return (
    <Modal show={show} width="550px" actions={updateActions}>
      <span>
        {"O equipamento"}
        <span className="text-bold text-uppercase"> {equipamentName} </span>
        {"ficará offline por alguns minutos e os clientes serão desconectados"}.
      </span>
    </Modal>
  );
};

export default ConfirmUpdateModal;
