import React, { useContext, useEffect } from "react";
import { MainHeaderContext } from "../components/main-header/main-header-state";

import "./main-app.css";
import "./inmaster/styles/styles-core.scss";
import "react-loading-skeleton/dist/skeleton.css";

import { Route, Switch } from "react-router";
import UsersPage from "./pages/users/users-page";
import { DevicesPage } from "./pages/devices/devices-page";
import { KeycloakContext } from "./keycloak";
import { BackendContext } from "../backend/backend";
import NoAccessPage from "./pages/noaccess/noaccess-page";
import IspsPage from "./pages/isps/isps-page";
import AccountsPage from "./pages/accounts/accounts-page";
import OpLogPage from "./pages/oplog/oplog-page";
import UserContext from "./user-context";
import IdunMenu from "./idun-menu";
import QueryEquipament from "./inmaster/pages/QueryEquipament";
import DeviceDetails from "./inmaster/pages/DeviceDetails";
import MembersPage from "./inmaster/pages/Members";
import { TokenContext } from "./inmaster/contexts/TokenContext";

function getClasses(header) {
  return header.hidden.value
    ? ""
    : header.collapsed.value
    ? "with-header-collapsed"
    : header.extended.value
    ? "with-header-extended"
    : "with-header";
}

export default function MainApp() {
  const keycloak = useContext(KeycloakContext);
  const backend = useContext(BackendContext);
  const headerState = useContext(MainHeaderContext);
  const tokenContextInMaster = useContext(TokenContext);

  useEffect(() => {
    headerState.notifications.set(false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!keycloak) return;

    backend.setAuthInfo(keycloak);
    tokenContextInMaster.setKeyCloak(keycloak);

    // eslint-disable-next-line
  }, [keycloak]);

  return (
    <UserContext>
      <div id="main-app" className={getClasses(headerState)}>
        <IdunMenu></IdunMenu>

        <Switch>
          <Route
            path="/inmaster/place/:placeId/device-details/:id*"
            component={DeviceDetails}
          ></Route>
          <Route path="/inmaster/site/:id*" component={MembersPage}></Route>
          <Route
            path="/inmaster/query-equipaments*"
            component={QueryEquipament}
          ></Route>

          <Route path="/noaccess*" component={NoAccessPage}></Route>
          <Route path="/oplog*" component={OpLogPage}></Route>
          <Route path="/accounts*" component={AccountsPage}></Route>
          <Route path="/devices*" component={DevicesPage}></Route>
          <Route path="/isps*" component={IspsPage}></Route>
          <Route path="/users/:id" component={UsersPage}></Route>
          <Route path="/*" component={UsersPage}></Route>
        </Switch>
      </div>
    </UserContext>
  );
}
