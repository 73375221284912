import React from 'react'

export default function CloseIcon({size, color}){

    return (
        <svg fill={color} width={size} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M584.323867 511.944205L1008.876048 87.4944a51.187868 51.187868 0 1 0-72.482022-72.482022L511.944221 439.564559 87.494415 15.012378a51.187868 51.187868 0 1 0-72.482021 72.482022L439.564575 511.944205 15.012394 936.394011a51.187868 51.187868 0 1 0 72.482021 72.482021L511.944221 584.323851l424.449805 424.552181a50.983117 50.983117 0 0 0 72.482022 0 51.187868 51.187868 0 0 0 0-72.482021L584.323867 511.944205z" />
        </svg>
    )

}