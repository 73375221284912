import React from 'react'

export default function RoundedCheckIcon({size}){
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height="57" viewBox="0 0 57 57">
    <g fill="#00A335">
        <path d="M28.5 57C12.76 57 0 44.24 0 28.5S12.76 0 28.5 0 57 12.76 57 28.5C56.98 44.232 44.232 56.98 28.5 57zm0-55.26C13.72 1.74 1.74 13.72 1.74 28.5c0 14.78 11.98 26.76 26.76 26.76 14.78 0 26.76-11.98 26.76-26.76C55.243 13.728 43.272 1.757 28.5 1.74z"/>
        <path d="M12 26.59c.009-.417.253-.79.625-.955a1.01 1.01 0 0 1 1.104.19c3.915 3.891 10.022 9.917 11.947 11.716 1.955-1.793 12.625-12.364 16.57-16.242.266-.265.649-.363 1.005-.257.355.105.63.398.718.767.09.37-.02.76-.286 1.025C30.958 35.473 26.253 40 25.7 40c-.553 0-.671 0-13.385-12.639a1.093 1.093 0 0 1-.315-.77"/>
    </g>
</svg>

}