import React from "react";
import styles from "./RadioButton.module.css";

const RadioButton = ({
  id = "",
  checked = false,
  disabled = false,
  value = "",
  onChange,
  label
}) => {
  return (
    <div className={styles.radioButton}>
      <input
        type="radio"
        id={id}
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={styles.radioInput}
      />
      <label htmlFor={id} className={styles.radioLabel} onClick={() => onChange({target: {value}})}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
