import React, { useState } from "react";
import Tag from "../../../../libs/conecte-se/ui-components/Tags";
import Card from "../../../../libs/conecte-se/ui-components/Card";
import { ButtonIcon } from "../../../../libs/conecte-se/ui-components/Button";
import { MdUpload } from "react-icons/md";
import moment from "moment";
import styles from "./UpdateToLatestFirmwareVersionModal.module.css";
import classNames from 'classnames';

const UpdateToLatestFirmwareVersionModal = ({
  handleSelectFirmwareVersion,
  latestFirmwareVersion,
}) => {
  const [showChangeLog, setShowChangeLog] = useState(false);

  function formatChangeLog(changeLog) {
    const regex = /(\\r|\\t|\\n|»)/gm;

    const formattedChangeLog = changeLog.replace(regex, " ");
    return formattedChangeLog;
  }

  return (
    <div className="d-flex align-center justify-center flex-column">
      <Card
        borderColor="var(--color-brand-secondary-light)"
        className={classNames(["pa-1 px-4 mb-2 text-xl-xs", styles.cardContainer])}
      >
        <div className="d-flex justify-between align-center">
          <div className={styles.cardInfos}>
            <div className={styles.cardItem}>
              <span className="text-bold">{"Versão"}:</span>
              <span
                className="ml-2"
                id={`number-of-version-${latestFirmwareVersion.version}`}
              >
                {latestFirmwareVersion.version}
              </span>
            </div>
            <div className={styles.cardItem}>
              <span className="text-bold">{"Última atualização"}: </span>
              <span
                className="ml-2"
                id={`last-update-date-${latestFirmwareVersion.updated_at}`}
              >
                {moment(latestFirmwareVersion.updated_at).format("DD/MM/YYYY")}
              </span>
            </div>
            <div>
              <Tag color="mustard">Mais recente</Tag>
            </div>
            <div className={styles.cardItem}>
              <button
                className={styles.textButton}
                type="button"
                onClick={() => setShowChangeLog((prev) => !prev)}
              >
                {showChangeLog ? "Ver menos" : "Changelog"}
              </button>
              <ButtonIcon
                className="ml-4"
                onClick={() =>
                  handleSelectFirmwareVersion(latestFirmwareVersion.version)
                }
              >
                <MdUpload size={24} />
              </ButtonIcon>
            </div>
          </div>
        </div>
        <div
          className={
            showChangeLog ? styles.changeLogShell : styles.changeLogShellClosed
          }
        >
          <span className="text-xl-base">{formatChangeLog(latestFirmwareVersion.changelog)}</span>
        </div>
      </Card>
    </div>
  );
};

export default UpdateToLatestFirmwareVersionModal;
