import React from "react";
import RadioCard from './RadioCard';
import { EmptyMessage } from "../../../components/EmptyMessage";

function RadioSettings({
  deviceOnScreen,
  radios,
  isFetchedAfterMountDeviceInfo,
}) {
  if (isFetchedAfterMountDeviceInfo && radios.length === 0) {
    return (
      <div className="d-flex align-center justify-center">
        <EmptyMessage
          height="calc(100vh - 620px)"
          minHeight="190px"
          disabled={
            // deviceOnScreen?.device?.status === 'offline' ||
            !isFetchedAfterMountDeviceInfo
          }
          title={"Nenhum rádio"}
          subtitle={
            "Ainda não há rádio configurado neste equipamento."
          }
        />
      </div>
    );
  }

  return (
    <div>
      {isFetchedAfterMountDeviceInfo ? (
        radios?.map((radio) => {
          return (
            <RadioCard
              key={radio.band}
              title={`Rádio ${radio.band}GHz`}
              radioData={radio}
              isLoading={false}
            />
          );
        })
      ) : (
        <>
          <RadioCard
            title=""
            radioData={null}
            isLoading={!isFetchedAfterMountDeviceInfo}
          />
          <RadioCard
            title=""
            isEditing={false}
            radioData={null}
            isLoading={!isFetchedAfterMountDeviceInfo}
          />
        </>
      )}
    </div>
  )
}

export default RadioSettings;
