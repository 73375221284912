import { isNull } from "lodash";
import React, { useEffect } from "react";
import { Alert } from "../../../libs/conecte-se/ui-components/Alert";
import Modal from "../../../libs/conecte-se/ui-components/Modal";
import { useToast } from "../../../hooks/useToast";
import { api } from "../../../services/api/api";
import { useMutation } from "react-query";

const RestartDeviceModal = ({
  selectedDevices,
  show,
  onCancel,
  onClose,
  isRebooting,
  setIsRebooting,
}) => {
  const { addToast } = useToast();

  const rebootDeviceMutation = useMutation(
    () => {
      return api.devices.reboot({
        placeId: selectedDevices[0].place_id,
        deviceId: selectedDevices[0].id,
      });
    },
    {
      onSuccess: () => {
        addToast(
          "success",
          "Equipamento está sendo reiniciado, a ação acontecerá em segundo plano."
        );

        onClose();
      },
      onError: (error) => {
        if (error.response.status === 500) {
          addToast(
            "error",
            "Estamos passando por uma instabilidade, tente novamente mais tarde"
          );
        }
        onClose();
      },
    }
  );

  const rebootDevice = () => {
    rebootDeviceMutation.mutate();

    onClose();
  };

  const modalRestartActions = [
    {
      label: "CANCELAR",
      action: () => onCancel(),
      disabled: isRebooting,
    },
    {
      label: "REINICIAR",
      action: () => rebootDevice(),
      isLoading: isRebooting,
    },
  ];

  useEffect(() => {
    if (setIsRebooting && !isNull(setIsRebooting)) {
      setIsRebooting(rebootDeviceMutation.isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebootDeviceMutation.isLoading]);

  return (
    <Modal actions={modalRestartActions} show={show} width="550px">
      <div className="d-flex flex-column">
        <span className="text-sm-lg mb-2">Reiniciar este equipamento?</span>
        <Alert type="warning">
          Todos os clientes ligados a este equipamento serão desconectados.
        </Alert>
      </div>
    </Modal>
  );
};

RestartDeviceModal.defaultProps = {
  setIsRebooting: null,
  isRebooting: false,
};

export default RestartDeviceModal;
