import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./AssociatedWireless.module.css";
import classNames from "classnames";
import Tooltip from "../../../libs/conecte-se/ui-components/Tooltip";
import { EmptyMessage } from "../../../components/EmptyMessage";
import Table from "../../../libs/conecte-se/ui-components/Table";
import Tag from "../../../libs/conecte-se/ui-components/Tags";
import { generateColumns } from "../../../utils/tableUtils";

function AssociatedWireless({
  deviceOnScreen,
  isFetchedAfterMountDeviceInfo,
  deviceWirelessList,
}) {
  const renderSkeletons = (numberOfSkeletons = 8) => {
    const skeletons = [<Skeleton key={`${-1}-skeleton-table-wireless-associated`} height={50} className="mb-1" />];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-around my-4"
          key={`${i}-skeleton-table-wireless-associated`}
        >
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
        </div>
      );
    }
    return skeletons;
  };

  const renderNotSupportedFrequency = (text) => {
    return (
      <Tooltip text={"Este equipamento não suporta essa frequência de rádio"}>
        <span className={classNames([styles.textDisable])}>{text}</span>
      </Tooltip>
    );
  };

  const renderLabelFrequency = (frequency) => {
    if (frequency === "dualband" || frequency === "2.4GHz | 5GHz") {
      if (!deviceOnScreen.radio_list.find((radio) => radio.band === "2.4")) {
        return (
          <div className={classNames(["d-flex"])}>
            {renderNotSupportedFrequency("2.4GHz |")}
            <span> 5GHz</span>
          </div>
        );
      }

      if (!deviceOnScreen.radio_list.find((radio) => radio.band === "5")) {
        return (
          <div className={classNames(["d-flex"])}>
            <span>2.4GHz </span>
            {renderNotSupportedFrequency("| 5GHz")}
          </div>
        );
      }

      if (
        deviceOnScreen.radio_list.find((radio) => radio.band === "2.4") &&
        deviceOnScreen.radio_list.find((radio) => radio.band === "5")
      ) {
        return <span>2.4GHz | 5GHz</span>;
      }

      return (
        <span className={classNames([styles.textDisable])}>2.4GHz | 5GHz</span>
      );
    }
    return <span>{frequency}GHz</span>;
  };

  const securityFormat = {
    Open: "Aberta",
    "WPA-PSK": "WPA-PSK",
    "WPA2-PSK": "WPA2-PSK",
  };

  const columns = [
    generateColumns("id", "ID", "left", 0, true),
    generateColumns("name", "Nome", "left", 0),
    generateColumns("security", "Segurança", "left", 0),
    generateColumns("frequency", "Frequência", "left", 0),
    generateColumns("isVisible", "Visibilidade", "left", 0),
  ];

  const createRowToDisplay = (wirelessList) => {
    if (!wirelessList) {
      return [];
    }
    const auxWirelessListRow = wirelessList.map((wireless) => {
      return {
        ...wireless,
        frequency: renderLabelFrequency(wireless.frequency),
        security: securityFormat[wireless.security],
        isVisible: (
          <div key={wireless.id}>
            {wireless.enabled ? (
              <div>
                {wireless.hidden ? (
                  <Tag color="neutral-dark-4" outlined wide>
                    Oculta
                  </Tag>
                ) : (
                  <Tag color="blue" outlined wide>
                    Visível
                  </Tag>
                )}
              </div>
            ) : (
              <Tag color="lightblue" outlined wide>
                Desabilitada
              </Tag>
            )}
          </div>
        ),
      };
    });

    return auxWirelessListRow;
  };

  if (
    isFetchedAfterMountDeviceInfo &&
    deviceWirelessList &&
    deviceWirelessList.length === 0
  ) {
    return (
      <div className="d-flex align-center justify-center">
        <EmptyMessage
          height="calc(100vh - 620px)"
          minHeight="190px"
          disabled={
            // deviceOnScreen?.device?.status === 'offline' ||
            !isFetchedAfterMountDeviceInfo
          }
          title={"Nenhuma wireless associada"}
          subtitle={
            "Ainda não há wireless associada disponível neste equipamento."
          }
        />
      </div>
    );
  }

  return (
    <div>
      {isFetchedAfterMountDeviceInfo ? (
        <Table
          noSelection
          className={classNames([styles.table])}
          columns={columns}
          rows={createRowToDisplay(deviceWirelessList)}
        />
      ) : (
        renderSkeletons()
      )}
    </div>
  );
}

export default AssociatedWireless;
