import React, { useState } from 'react'
import BackendDriver from './drivers/rest'

const APPLY_ACTION = 5

export const backendStatus = {
    SUCCESS: 1,
    ERROR: 2,
    UNAUTHORIZED: 3,
}

export const BackendContext = React.createContext()

var data = {}
var driver = new BackendDriver(process.env.REACT_APP_REST_ENDPOINT)
var njordDriver = new BackendDriver(process.env.REACT_APP_NJORD_URL || process.env.REACT_APP_REMOTIZE_URL)

export default function Backend({children}){

    const [auth, setAuth] = useState(null)
    const [authHandlerEnabled, setAuthHandlerEnabled] = useState(true)
    const [onUnauthorized, setOnUnauthorized] = useState(() => defaultUnauthorizedHandler)
    const [isDefaultUnauthorizedHandler, setIsDefaultUnauthorizedHandler] = useState(true)
    const [needApply, setNeedApply] = useState(false)

    function defaultUnauthorizedHandler() {
        console.warn("Received an 'unauthorized' response from backend."  +
            "You can implement your own handler setting an 'onUnauthorized' " +
            "handler on backend. (backend.setUnauthorizedHandler(handlerFn)")

    }

    function setOnUnauthorizedHandler(handlerFn){
        setIsDefaultUnauthorizedHandler(false)
        setOnUnauthorized(() => handlerFn)
    }

    async function handleDriverResponse(driverResponse, key, refresh) {

        if(driverResponse.status === backendStatus.UNAUTHORIZED){
            if(authHandlerEnabled) onUnauthorized(refresh)
            return
        }

        if(driverResponse.status === backendStatus.SUCCESS)
            data[key] = driverResponse.content

    }

    async function backendApply(){

        if (needApply) {

            const driverResponse = await driver.create('action', {actionID: APPLY_ACTION}, null, auth)

            handleDriverResponse(driverResponse, null, true)

            return driverResponse

        }
    }

    async function create(key, data, headers, apply = true) {//FIXME: turn headers into some kind of driver agnostic data

        let driverResponse = await driver.create(key, data, headers, auth)
        
        handleDriverResponse(driverResponse, key, true)

        if (apply)
            await backendApply()

        return driverResponse
    }

    async function upload(key, data, headers) {//FIXME: turn headers into some kind of driver agnostic data

        let driverResponse = await driver.upload(key, data, headers, auth)
        
        handleDriverResponse(driverResponse, key, true)

        return driverResponse
    }

    async function retrieve(key) {

        if(!data[key])
            return await retrieveFresh(key)

        return {
            status: backendStatus.SUCCESS,
            content: data[key]
        }
    }

    async function retrieveFresh(key) {
        const driverResponse = await driver.retrieve(key, auth)

        handleDriverResponse(driverResponse, key, true)
        
        return driverResponse
    }

    async function update(key, value, headers, apply = true){ //FIXME: turn headers into some kind of driver agnostic data
        
        const driverResponse = await driver.update(key, value, headers, auth)
            
        handleDriverResponse(driverResponse, null, true)

        if(driverResponse.status === backendStatus.SUCCESS && data[key])
            data[key] = value

        if (apply)
            await backendApply()

        return driverResponse
    }

    async function backendDelete(key, value, apply = true){ //FIXME: remove value parameter from APIs

        const driverResponse = await driver.delete(key, value, auth)
            
        handleDriverResponse(driverResponse, null, true)

        if(driverResponse.status === backendStatus.SUCCESS && data[key])
            delete data[key]

        if (apply)
            await backendApply()

        return driverResponse       
    }

    async function njordCreate(key, data) {
        const driverResponse = await njordDriver.create(key, data, null, auth)

        handleDriverResponse(driverResponse, key, false)

        return driverResponse
    }

    async function njordRetrieve(key) {
        const driverResponse = await njordDriver.retrieve(key, auth)

        handleDriverResponse(driverResponse, key, false)

        return driverResponse
    }

    async function njordUpdate(key, data){
        const driverResponse = await njordDriver.update(key, data, null, auth)

        handleDriverResponse(driverResponse, null, false)

        return driverResponse
    }

    async function njordDelete(key, data){
        const driverResponse = await njordDriver.delete(key, data, auth)

        handleDriverResponse(driverResponse, null, false)

        return driverResponse
    }

    async function flushCache(key) {

        if(key){
            delete data[key]
            return
        }

        data = {}
    }

    function setDriverBaseUrl(url) {
        driver.setBaseUrl(url)
    }

    function getDriverBaseUrl() {
        return driver.getBaseUrl()
    }

    return <BackendContext.Provider value={{

        create: create,
        retrieve: retrieve,
        retrieveFresh: retrieveFresh,

        update: update,

        delete: backendDelete,

        njordCreate: njordCreate,
        njordRetrieve: njordRetrieve,
        njordUpdate: njordUpdate,
        njordDelete: njordDelete,

        upload: upload,
        
        setOnUnauthorized: setOnUnauthorizedHandler,
        isDefaultUnauthorizedHandler: isDefaultUnauthorizedHandler,

        setAuthInfo: setAuth,
        auth: auth,

        flushCache: flushCache,

        setDriverBaseUrl: setDriverBaseUrl,
        getDriverBaseUrl: getDriverBaseUrl,

        setAuthHandlerEnabled: setAuthHandlerEnabled,

        setNeedApply: setNeedApply,

        backendApply: backendApply

    }}>{children}</BackendContext.Provider>
}