import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Button from '../../../components/button/button'
import Form from '../../../components/form/form'
import RemoveIcon from '../../../components/icons/remove'
import RoundedForbiddenIcon from '../../../components/icons/rounded-forbidden'
import Input from '../../../components/input/input'
import List from '../../../components/list/list'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Modal, { ModalTypes } from '../../../components/modal/modal'
import Select from '../../../components/select/select'
import { UserContext } from '../../user-context'

import './accounts.css'


const accountTypeOptions = [
    {value: 0, text: 'Bloqueado'},
    {value: 1, text: 'Operador'},
    {value: 2, text: 'Administrador'},
]

const ADMIN_USER = 2

export default function AccountsPage() {

    const [users, setUsers] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [deletingUser, setDeletingUser] = useState({name:''})
    const [error, setError] = useState(false)
    const [addUser, setAddUser] = useState(false)
    const [newUser, setNewUser] = useState({username: '', type: 1})

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const mainUser = useContext(UserContext)
    const history = useHistory()

    useEffect(() => {

        header.title.set('Usuários do Help Desk')

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(mainUser.type !== ADMIN_USER){
            history.push('/')
            return
        }

        fetchUsers()

        // eslint-disable-next-line
    }, [mainUser])

    const fetchUsers = async() => {

        setUsers(null)

        let result = await backend.retrieveFresh('hdusers')
        if(result.status === backendStatus.SUCCESS){
            setUsers(result.content)
        }
    }

    const updateUserPermission = async(user, value) => {

        user.type = Number(value)

        let result = await backend.update(`hdusers/${user.username}`, user)
        if(result.status === backendStatus.SUCCESS){
            fetchUsers()
            return
        }

    }

    const confirmAccountDelete = (user) => {

        setDeletingUser(user)
        setConfirm(true)
    }

    const dismissDelete = async(confirmed) => {

        if(confirmed){
            let result = await backend.delete(`hdusers/${deletingUser.username}`)
            if(result.status !== backendStatus.SUCCESS){
                setError(true)
            }
        }

        setConfirm(false)
        fetchUsers()
    }

    const createUser = async() => {

        setAddUser(false)

        await backend.create(`hdusers`, newUser)

        fetchUsers()
    }

    const addUserModal = () => {
        return <div className='user-add-modal'>
            <Form submitText='Adicionar' onSubmit={createUser}>
                <Input
                    label='Matrícula'
                    value={newUser.username}
                    onChange={e => setNewUser({...newUser, username: e.target.value})}
                ></Input>

                <Select
                    label='Nível de acesso'
                    value={newUser.type}
                    options={accountTypeOptions}
                    onChange={e => setNewUser({...newUser, type: Number(e.target.value)})}
                ></Select>
            </Form>
        </div>
    }

    if(!users)
        return <Loading show={true}></Loading>

    return <div id='accounts-page' className='page'>

        <Modal
            type={ModalTypes.INFO}
            show={addUser}
            content={addUserModal()}
            onOverlayClick={() => setAddUser(false)}
        >
        </Modal>

        <Modal id='confirm-delete'
            show={confirm && deletingUser}
            type={ModalTypes.CONFIRM_WARNING}
            content={
                <div id='confirm-delete-modal'>
                    <RoundedForbiddenIcon></RoundedForbiddenIcon>
                    O usuário <b>{deletingUser.name}</b> será removido do sistema.
                    <br></br>
                    <br></br>
                    <b>Tem certeza que deseja remover o usuário {deletingUser.name}?</b>
                    <br></br>
                </div>
            }
            confirmText='Sim, quero remover'
            dismissText='Não, quero manter'
            onDismissClick={dismissDelete}
        ></Modal>

        <Modal id='delete error'
            show={error}
            type={ModalTypes.ERROR}
            content='Houve um erro ao remover o usuário. Tente novamente mais tarde.'
            dismissText='Entendi'
            onDismissClick={() => setError(false)}
        ></Modal>

        <Button id='add-user-button'
            text='Adicionar usuário'
            onClick={() => setAddUser(true)}
        ></Button>

        <List
            width='850px'
            columns={[
                {header: 'Nome'},
                {header: 'Matrícula'},
                {header: 'Nível de acesso', size: '185px'},
                {header: 'Remover', size: '100px', align: 'center'}
            ]}
            lines={
                users.map(user => {
                    return [
                        user.name,
                        user.username,
                        user.username === mainUser.username ? accountTypeOptions[user.type].text : <Select id='user-list-select'
                            value={user.type}
                            options={accountTypeOptions}
                            onChange={e => updateUserPermission(user, e.target.value)}
                        ></Select>,
                        mainUser.username !== user.username ? <div className='accounts-delete' onClick={() => confirmAccountDelete(user)}>
                            <RemoveIcon></RemoveIcon>
                        </div> : ''
                    ]
                })
            }
        >
        </List>
    </div>
}