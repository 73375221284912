import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Form from '../../../components/form/form'
import SearchIcon from '../../../components/icons/search-icon'
import Input from '../../../components/input/input'
import List from '../../../components/list/list'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import CnpjUtils from '../cpnj-utils'
import oplogTranslate, { oplogTypes } from './oplog-translate'

import './oplog.css'

function isCNPJ(value) {

    let regex = /\d{2}(.\d{3}){2}\/\d{4}-\d{2}/gm

    return regex.test(value)
}


export default function OpLogPage() {

    const [search, setSearch] = useState('')
    const [logs, setLogs] = useState([])
    const [logData, setLogData] = useState(null)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)

    useEffect(() => {
        header.title.set('Log de operações')

        // eslint-disable-next-line 
    }, [])

    const searchLogs = async() => {

        setLogs(null)

        let param = search

        if(isCNPJ(param)){
            param = CnpjUtils.removeMask(param)
        }

        let result = await backend.retrieveFresh(`oplog/${param}`)

        if(result.status === backendStatus.SUCCESS){
            setLogs(result.content)
            return
        }

        setLogs([])
    }

    const getSearchIcon = (log) => {

        if(log.type === oplogTypes.REMOTIZE_DEVICE_DESASSOCIATION ||
           log.type === oplogTypes.REMOTIZE_DEVICE_PROFILE_REAPLY)
            return ''

        return <div className='oplog-data-icon' onClick={(e) => setLogData(log)}>
            <SearchIcon size='20'></SearchIcon>
        </div>
    }

    const getLogsLines = () => {

        return logs.map( log => {

            let date = new Date(log.date * 1000)
            let dateString = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`

            return [log.username, dateString, oplogTranslate(log), getSearchIcon(log)]
        })
    }

    return <div className='oplog-page'>

        {logData && <div className='oplog-overlay' onClick={() => setLogData(null)}>
            <div className='log-data-container'>
                <div className='log-data'>
                    <div className='subtitle'>Antes</div>
                    <pre>{JSON.stringify(logData.from, null, 2)}</pre>
                </div>
                <div className='log-data'>
                    <div className='subtitle'>Depois</div>
                    <pre>{JSON.stringify(logData.to, null, 2)}</pre>
                </div>
            </div>
        </div>}

        <Form onSubmit={searchLogs} id='devices-search' submitText='Buscar' buttonId='search-button'>

            <Input id='search-input'
                value={search}
                onChange={e => {
                    setSearch(e.target.value)
                }}
                placeholder='Matricula, CNPJ, email ou MAC'
            ></Input>

        </Form>

        {!logs ? <Loading show={true}></Loading> : <div className='flex-sections-container'>

                <List width='100%'
                    columns={[
                        {header: 'Operador'},
                        {header: 'Data', size:'180px', align: 'center'},
                        {header: 'Operação', size: '700px', align: 'left'},
                        {header: 'Dados', size: '100px', align: 'right'}
                    ]}
                    lines={getLogsLines()}
                ></List>

        </div>}
    </div>
}