import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelect } from "downshift";

import { CSSTransition } from "react-transition-group";

import styles from "./Select.module.css";
import classNames from "classnames";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import _ from "lodash";

const Select = ({
  options = [],
  onChange = (e) => {},
  placeholder = "",
  isInvalid = false,
  onBlur = () => {},
  getLastOptionAsDefault = false,
  value = "",
  ...props
}) => {
  const { getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } =
    useSelect({
      items: options,
      onSelectedItemChange: ({ selectedItem }) =>
        handleChangeItem(selectedItem),
      onIsOpenChange: ({ isOpen }) => (!isOpen ? closeMenu() : null),
    });

  const handleChangeItem = (selectedItem) => {
    onChange(selectedItem.value);
    setShowItems(false);
  };
  const closeMenu = () => {
    setShowItems(false);
    onBlur();
  };

  const renderLabel = useMemo(() => {
    const label = options.find((option) => _.isEqual(option.value, value));
    if (!label) return "";
    return label.label;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, onChange]);

  useEffect(() => {
    for (const option of options) {
      if (option.value !== value) {
        continue;
      }
      return;
    }

    if (options.length > 0 && !placeholder.length) {
      if (getLastOptionAsDefault) {
        return onChange(options[options.length - 1].value);
      }
      return onChange(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, onChange]);

  const [showItems, setShowItems] = useState(false);
  const shellSelectItemsRef = useRef(null);
  return (
    <React.Fragment>
      <div className={styles.shellSelect}>
        <button
          {...props}
          className={classNames([styles.select, isInvalid && styles.error])}
          type="button"
          {...getToggleButtonProps({
            onClick: () => setShowItems(!showItems),
            id: props.id,
          })}
        >
          <div>
            {value !== "" ? (
              <span>{renderLabel}</span>
            ) : (
              <span className={styles.placeholder}>{placeholder}</span>
            )}
          </div>
          <div className={styles.iconOfSelect}>
            {showItems ? (
              <MdArrowDropUp size={24}></MdArrowDropUp>
            ) : (
              <MdArrowDropDown size={24}></MdArrowDropDown>
            )}
          </div>
        </button>
        <CSSTransition
          in={showItems}
          timeout={3}
          classNames={{
            enterActive: styles.shellSelectItemsEnterActive,
            enterDone: styles.shellSelectItemsEnterDone,
            exitActive: styles.shellSelectItemsEnterExitActive,
            exitDone: styles.shellSelectItemsDrawerExitDone,
          }}
          nodeRef={shellSelectItemsRef}
        >
          <ul
            {...getMenuProps({
              ref: shellSelectItemsRef,
            })}
            className={styles.shellSelectItems}
          >
            {options.map((item, index) => (
              <li
                className={classNames([
                  styles.selectItem,
                  highlightedIndex === index ? styles.selectItemActive : null,
                ])}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
                id={item.id ? item.id : getItemProps({ item, index }).id}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </CSSTransition>
        {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
        <div tabIndex="0" />
      </div>
    </React.Fragment>
  );
};

export default Select;
