import React, { useEffect, useMemo, useState } from "react";
import Card from "../../libs/conecte-se/ui-components/Card";
import Label from "../../libs/conecte-se/ui-components/Label";
import { InputWrapper } from "../../libs/conecte-se/ui-components/InputWrapper";
import InputHelper from "../../libs/conecte-se/ui-components/InputHelper";
import { MdSearch } from "react-icons/md";
import Loading from "../../libs/conecte-se/ui-components/Loading";
import { Input } from "../../libs/conecte-se/ui-components/Input";
import RadioButton from "../../libs/conecte-se/ui-components/RadioButton";
import TableWithPagination from "../../components/TableWithPagination";
import { generateColumns } from "../../utils/tableUtils";
import { api } from "../../services/api/api";
import { useDebounce } from "use-debounce";
import { useQuery } from "react-query";
import classNames from "classnames";
import styles from "./styles.module.css";
import { EmptyMessage } from "../../components/EmptyMessage";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../libs/conecte-se/ui-components/Button";
import Tag from "../../libs/conecte-se/ui-components/Tags";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputMask from "react-input-mask";
import { MdCancel } from "react-icons/md";
import { MainHeaderContext } from "../../../../components/main-header/main-header-state";
import Tooltip from "../../libs/conecte-se/ui-components/Tooltip";

function QueryEquipament() {
  const history = useHistory();
  const header = React.useContext(MainHeaderContext);
  const [search, setSearch] = React.useState({ value: "", type: "email" });
  const [isChangingPage, setIsChangingPage] = React.useState(false);
  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    React.useState({
      type: "success",
      isFirstPage: true,
    });

  const [errorMessage, setErrorMessage] = React.useState("");

  const [paginationRequestData, setPaginationRequestData] = useState({
    page_number: 1,
    page_size: 10,
    email: null,
    mac: null,
  });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const hasMACorEmail = useMemo(() => {
    return (
      !!paginationRequestDataDebounce.email ||
      !!paginationRequestDataDebounce.mac
    );
  }, [paginationRequestDataDebounce]);

  const [devicesPagination, setDevicesPagination] = useState({});

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  const currentPath = `/inmaster/query-equipaments?${
    search.type
  }=${encodeURIComponent(search.value)}`;

  const macMask = [
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
  ];

  const columnsOfTable = [
    generateColumns("id", "ID", "left", 0, true),
    generateColumns("email", "Usuário", "left", 0),
    generateColumns("permission", "Permissão", "left", 0),
    generateColumns("mac", "MAC", "left", 0),
    generateColumns("site", "Local", "left", 0),
    generateColumns("place", "Ambiente", "left", 0),
    generateColumns("status", "Status", "left", 0),
  ];

  const getPaginationDevices = () => {
    if (search.type === "mac") {
      return api.devices.getByMac(paginationRequestDataDebounce);
    }

    return api.devices.getByEmail(paginationRequestDataDebounce);
  };

  const translatePermission = (permission) => {
    switch (permission) {
      case "owner":
        return "Proprietário";
      case "manager":
        return "Gerente";
      case "guest":
        return "Convidado";
      default:
        return "Sem permissão";
    }
  };

  const generateRowsWithJSX = (rows) => {
    if (rows === undefined) return;
    return rows.map((row) => {
      return {
        id: row.id,
        email: row.member.user.email,
        permission: translatePermission(row.member.role),
        mac: (
          <Link
            className={styles.linkToMacAndSite}
            to={{
              pathname: `/inmaster/place/${row.place.id}/device-details/${row.id}`,
              state: { prevPath: currentPath },
            }}
          >
            {row.mac}
          </Link>
        ),
        site: (
          <Link
            className={styles.linkToMacAndSite}
            to={{
              pathname: `/inmaster/site/${row.site.id}`,
              state: { prevPath: currentPath },
            }}
          >
            {row.site.name}
          </Link>
        ),
        place: row.place.name,
        status: (
          <div className={styles.statusCel}>
            <Tag color={row.status === "offline" ? "red" : "green"} wide>
              {row.status}
            </Tag>
            {!row.has_associated_wireless ? (
              <div className={styles.tooltipTagContainer}>
                <Tooltip
                  text="Equipamento sem wireless"
                  toolTipStyle={{ width: 190 }}
                >
                  <Tag color="wine" wide>
                    sem wireless
                  </Tag>
                </Tooltip>
              </div>
            ) : null}
          </div>
        ),
      };
    });
  };

  const renderSkeletonsOfTable = (numberOfSkeletons = 7) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 align-center py-5"
          key={`${i}-skeleton-wireless-table`}
        >
          <Skeleton count={1} height={14} width={193} className="ml-2" />
          <Skeleton count={1} height={14} width={76} />
          <Skeleton count={1} height={14} width={116} />
          <Skeleton count={1} height={14} width={50} />
          <Skeleton count={1} height={14} width={70} />
          <Skeleton count={1} height={30} width={76} borderRadius={100} />
        </div>
      );
    }

    return skeletons;
  };

  const resetPaginationRequestData = () => {
    setPaginationRequestData({
      email: null,
      mac: null,
      page_number: 1,
      page_size: 10,
    });
  };

  const hasErrorMessages = useMemo(() => {
    return !!errorMessage;
  }, [errorMessage]);

  const hasDevices = useMemo(() => {
    return (
      devicesPagination &&
      devicesPagination.devices &&
      devicesPagination.devices.length > 0
    );
  }, [devicesPagination]);

  const { isFetching: isFetchingDevices, refetch: refetchDevices } = useQuery(
    ["getDevices", paginationRequestDataDebounce],
    getPaginationDevices,
    {
      enabled: hasMACorEmail,
      onSuccess: ({ data }) => {
        if (!isInitialLoadingComplete) {
          setIsInitialLoadingComplete(true);
        }

        setDevicesPagination(data);
        if (data.pagination.total_items === 0) {
          setErrorMessage("Nenhum equipamento encontrado");
          resetPaginationRequestData();
        }
      },
      onError: (error) => {
        if (!isInitialLoadingComplete) {
          setIsInitialLoadingComplete(true);
        }

        setErrorMessage(error.response.data[0].msg);
        resetPaginationRequestData();
        setDevicesPagination({});
      },
      retry: 0,
    }
  );

  const validateEmail = (email) => {
    const specialCaracteresRegex = /^[a-zA-Z0-9@._]+$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim().length === 0)
      return "Digite um email válido para pesquisar";
    if (email.startsWith(" ") || email.endsWith(" "))
      return "O email não pode começar nem terminar com um espaço";
    if (!specialCaracteresRegex.test(email))
      return "O email não pode conter espaços nem caracteres especiais";
    if (!email.indexOf(" "))
      return "O email não pode conter espaços nem caracteres especiais";
    if (!emailRegex.test(email)) {
      return "Endereço de email inválido";
    }
    return;
  };

  const validateMac = (mac) => {
    const macRegex = /^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$/;
    if (mac.trim().length === 0) return "Digite um MAC válido para pesquisar";
    if (mac.indexOf("_") !== -1) {
      return "MAC incompleto";
    }
    if (!macRegex.test(mac)) {
      return "MAC inválido";
    }
    return;
  };

  const sendRequestToGetDevices = () => {
    if (search.type === "email" && validateEmail(search.value)) {
      setErrorMessage(validateEmail(search.value));
      return;
    }

    if (search.type === "mac" && validateMac(search.value)) {
      setErrorMessage(validateMac(search.value));
      return;
    }

    setErrorMessage("");

    const searchUrl = history.location.search;

    if (decodeURIComponent(searchUrl).includes(search.value) && hasDevices) {
      refetchDevices();
      return;
    }

    const newUrl = `/inmaster/query-equipaments?${
      search.type
    }=${encodeURIComponent(search.value)}`;

    history.push(newUrl);

    setPaginationRequestData({
      email: search.type === "email" ? search.value : null,
      mac: search.type === "mac" ? search.value : null,
      page_number: 1,
      page_size: 10,
    });
  };

  const getEmailFromUrl = () => {
    const url = window.location.href;
    const emailIndex = url.indexOf("?email=");
    if (emailIndex !== -1) {
      const emailStartIndex = emailIndex + 7; // O tamanho de "?email="
      const emailEndIndex = url.indexOf("&", emailStartIndex);
      const email =
        emailEndIndex !== -1
          ? url.substring(emailStartIndex, emailEndIndex)
          : url.substring(emailStartIndex);
      return decodeURIComponent(email.replace("%40", "@"));
    }
    return "";
  };

  const getMacFromUrl = () => {
    const url = window.location.href;
    const macIndex = url.indexOf("?mac=");
    if (macIndex !== -1) {
      const macStartIndex = macIndex + 5; // O tamanho de "?mac="
      const macEndIndex = url.indexOf("&", macStartIndex);
      const mac =
        macEndIndex !== -1
          ? url.substring(macStartIndex, macEndIndex)
          : url.substring(macStartIndex);
      return decodeURIComponent(mac);
    }
    return "";
  };

  const getInputConfig = (type) => {
    if (type === "mac") {
      return {
        placeholder: "00:00:00:00:00:00",
        mask: macMask,
      };
    }

    return {
      placeholder: "fulanodetal@email.com",
      mask: null,
    };
  };

  const handleTypeOfSearchChange = (type) => {
    setSearch({ type: type, value: "" });
    setErrorMessage("");
    resetPaginationRequestData();
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    if (search.type === "mac") {
      setSearch((prev) => {
        return { ...prev, value: value.toUpperCase() };
      });
    } else {
      setSearch((prev) => {
        return { ...prev, value: value };
      });
    }
  };

  useEffect(() => {
    const emailFromUrl = getEmailFromUrl();
    const macFromUrl = getMacFromUrl();
    const hasNoSearch = !emailFromUrl && !macFromUrl;
    setIsInitialLoadingComplete(hasNoSearch ? true : false);
    if (emailFromUrl) {
      setSearch({ value: emailFromUrl, type: "email" });
      setPaginationRequestData((prev) => {
        const requestData = { ...prev, email: emailFromUrl };
        return requestData;
      });
    } else if (macFromUrl) {
      setSearch({ value: macFromUrl, type: "mac" });
      setPaginationRequestData((prev) => {
        const requestData = { ...prev, mac: macFromUrl };
        return requestData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    header.title.set("");
    const historyState = history.location;

    if (historyState.search === "") {
      header.backRoute.set(null);
    } else {
      header.backRoute.set(historyState.pathname + historyState.search);
    }

    // eslint-disable-next-line
  }, [history.location.search]);

  return (
    <div className={classNames(styles.body)}>
      <div className="row">
        <h2
          className="title-xl-base text-uppercase"
          style={{ color: "var(--color-neutral-dark-3)", marginLeft: 7 }}
        >
          Consultas
        </h2>
      </div>
      <Card className="pa-7">
        <div className="row">
          <div
            className={classNames([
              "col-xs-12 d-flex justify-start",
              hasErrorMessages ? "align-center" : "align-end",
            ])}
          >
            <InputWrapper invalid={hasErrorMessages}>
              <Label>Pesquisar</Label>

              <InputMask
                mask={getInputConfig(search.type).mask}
                value={search.value || ""}
                onChange={(e) => {
                  handleSearchInput(e);
                }}
              >
                <Input
                  id="search-input"
                  value={search.value || ""}
                  placeholder={getInputConfig(search.type).placeholder}
                />
              </InputMask>

              {hasErrorMessages && (
                <InputHelper
                  id="error-message"
                  icon={<MdCancel />}
                  value={errorMessage}
                />
              )}
            </InputWrapper>

            <Button
              className={classNames([
                "ml-3 d-flex align-center justify-center",
                styles.searchButton,
              ])}
              outline
              onClick={() => sendRequestToGetDevices()}
              disabled={isFetchingDevices}
            >
              {isFetchingDevices ? (
                <Loading indeterminate value={30} />
              ) : (
                <>
                  <MdSearch size={20} /> <span className="ml-2">Buscar</span>
                </>
              )}
            </Button>
          </div>
          <div
            className={classNames([
              "col-xs-12 d-flex justify-start",
              hasErrorMessages ? "mt-2" : "mt-6",
              styles.radioContainer,
            ])}
          >
            <RadioButton
              value="email"
              checked={search.type === "email"}
              onChange={(e) => handleTypeOfSearchChange(e.target.value)}
              label="Email do usuário"
            />
            <RadioButton
              value="mac"
              checked={search.type === "mac"}
              onChange={(e) => handleTypeOfSearchChange(e.target.value)}
              label="Mac do equipamento"
            />
          </div>
        </div>
      </Card>
      <Card className={classNames(["mt-4 pa-7 fit-height", styles.cardTable])}>
        <div className="row fit-height">
          <div className="col-xs-12 d-flex justify-start fit-height">
            {!hasDevices && !isFetchingDevices && isInitialLoadingComplete ? (
              <div
                className={classNames([
                  styles.messageEmptyShell,
                  "d-flex fit-width justify-center fit-height",
                ])}
              >
                <EmptyMessage
                  id="empty-search-equipaments"
                  title="Efetue uma busca no sistema"
                  subtitle="Experimente pesquisar pelo e-mail do usuário ou o endereço MAC do equipamento"
                  height="auto"
                  icon={<MdSearch size={33} />}
                />
              </div>
            ) : (
              <TableWithPagination
                noSelection
                isChangingPage={isChangingPage}
                loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                columns={columnsOfTable}
                setPaginationObject={setDevicesPagination}
                rows={generateRowsWithJSX(devicesPagination.devices)}
                isFetchingData={isFetchingDevices}
                isToShowSkeletons={isFetchingDevices}
                paginationData={devicesPagination.pagination}
                firstFetchWasMade={isInitialLoadingComplete}
                paginationRequestData={paginationRequestDataDebounce}
                renderSkeletonsOfTable={renderSkeletonsOfTable}
                setIsChangingPage={setIsChangingPage}
                setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
                setPaginationRequestData={setPaginationRequestData}
                keyOfItemsOfPaginationTable="devices"
                minRowsToShowPagination={10}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default QueryEquipament;
