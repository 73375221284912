import React, { createContext, useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import Toast from "../../libs/conecte-se/ui-components/Toast";

export const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const deleteToast = (id) => {
    setToasts((prevState) => prevState.filter((toast) => toast.id !== id));
  };

  const dismissAllToasts = () => {
    setToasts((prevState) => {
      return prevState.map((toast) => ({
        ...toast,
        options: {
          ...toast.options,
          autoDismiss: true,
        },
      }));
    });
  };

  const addToast = useCallback((type, content, options) => {
    setToasts((prevState) => {
      if (
        options?.unique &&
        prevState.find((toast) => toast.content === content)
      ) {
        return prevState;
      }
      return [
        ...prevState,
        {
          id: uuid(),
          type,
          content,
          options: {
            unique: options?.unique ?? false,
            autoDismiss: options?.autoDismiss ?? true,
            closable: options?.closable ?? false,
          },
        },
      ];
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      addToast,
      dismissAllToasts,
    }),
    [addToast]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast
        toastList={toasts}
        onDismiss={deleteToast}
        autoDismiss
        dismissTime={4000}
      />
    </ToastContext.Provider>
  );
};
