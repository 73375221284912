import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Form from '../../../components/form/form'
import EditIcon from '../../../components/icons/edit'
import Input from '../../../components/input/input'
import List from '../../../components/list/list'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import CnpjUtils from '../cpnj-utils'
import { ispForm } from '../users/users-page'
import { RemotizeUserTypeOptions } from '../users/users-page'
import { useHistory } from 'react-router-dom'


import './isps.css'
import DefaultStatusModals from '../../../components/modal/default-status-modals'

export default function IspsPage() {

    const [cnpj, setCNPJ] = useState('')
    const [isp, setISP] = useState(null)
    const [users, setUsers] = useState(null)

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {

        header.title.set('Provedores')

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!isp || users) return

        fetchUsers()

        // eslint-disable-next-line 
    }, [isp])

    const fetchUsers = async() => {

        let result = await backend.retrieveFresh(`isps/${isp.ispid}/users`)
        if(result.status === backendStatus.SUCCESS){
            setUsers(result.content)
        }
    }

    const searchISP = async() => {

        let result = await backend.retrieveFresh(`isps/${CnpjUtils.removeMask(cnpj)}`)
        if(result.status === backendStatus.SUCCESS){
            setISP(result.content)
        }
    }

    const saveIsp = async() => {

        setSaving(true)

        let result = await backend.update('isps', isp)

        setSaving(false)

        if(result.status !== backendStatus.SUCCESS){
            setError(true)
            return
        }

        setSuccess(true)

    }

    const closeModal = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const usersList = () => {
        return <div className='section'>
            <div className='section-card isp-users-list'>

                <div className='subtitle'>Usuários do provedor</div>

                <List width='800px'
                    columns={[{header:'Nome'}, {header:'Email'}, {header: 'Nível de acesso', size:'140px'}, {header: 'Ações', size: '80px'}]}
                    lines={users.map(user => {
                        return [
                            user.name,
                            user.email,
                            RemotizeUserTypeOptions[user.type].text,
                            <div onClick={() => history.push(`/users/${user.username}`)}>
                                <EditIcon size='25'></EditIcon>
                            </div>
                        ]
                    })}
                ></List>

            </div>
        </div>
    }

    return <div className='isps-page'>

        <DefaultStatusModals
            saving={saving}
            success={success}
            error={error}
            continueFn={closeModal}
        ></DefaultStatusModals>

        <Form onSubmit={searchISP} id='users-search' submitText='Buscar' buttonId='search-button'>

            <Input id='search-input'
                value={cnpj}
                onChange={e => {

                    if(!CnpjUtils.isValidValue(e))
                        return

                    setCNPJ(e.target.value)

                }}
                onBlur={() => setCNPJ(CnpjUtils.applyMask(cnpj))}
                onFocus={() => setCNPJ(CnpjUtils.removeMask(cnpj))}
                placeholder='CNPJ'
            ></Input>

        </Form>

        <div className='flex-sections-container'>
            {isp && ispForm(isp, setISP, saveIsp)}
            {users && usersList()}
        </div>

    </div>
}