import React from 'react'

import './tooltip-text.css'

export default function TooltipText(props) {
    const getStyle = () => props.width ? {width: props.width} : {}

    return (
        <div className='tooltiptext' style={getStyle()}>
            {props.message}
        </div>
    )
}
