import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Button from '../../../components/button/button'
import Form from '../../../components/form/form'
import InfoLine from '../../../components/info-line/info-line'
import Input from '../../../components/input/input'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import DefaultStatusModals from '../../../components/modal/default-status-modals'
import Modal, { ModalTypes } from '../../../components/modal/modal'

import './devices.css'

const DeviceStatus = [
    'Aguardando',
    'Offline',
    'Online'
]

const AssocStatus = [
    'Não associado',
    'Associando',
    'Associado'
]

const ProfileStatus = [
    'Sem perfil',
	'Aplicando',
	'Confirmado',
	'Resetando',
	'Reaplicar',
	'Voltar ao padrão'
]

export function DevicesPage() {

    const [search, setSearch] = useState('')
    const [device, setDevice] = useState(null)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [desassocConfirm, setConfirmDesassoc] = useState(false)
    const [reaplyConfirm, setReaplyConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        header.title.set('Dispositivos')

        // eslint-disable-next-line
    }, [])

    const searchDevice = async() => {

        setDevice(null)
        setLoading(true)

        let result = await backend.retrieveFresh(`devices/${search}`)
        if(result.status === backendStatus.SUCCESS){
            setDevice(result.content)
        }

        setLoading(false)
    }

    const getDateString = (value) => {

        let date = new Date(value)

        return date.toLocaleString()
    }

    const deviceInfo = () => {

        return  <div className='section'>

            <div className='device-info section-card'>

                <div className='subtitle'>Informações do dispositivo</div>

                <InfoLine label='Modelo' value={device.model}></InfoLine>
                <InfoLine label='ID do dispositivo' value={device.deviceid}></InfoLine>
                <InfoLine label='Versão de firmware' value={device.fw_version}></InfoLine>
                <InfoLine label='Estado de conexão' value={DeviceStatus[device.status]}></InfoLine>
                <InfoLine label='Primeira conexão' value={getDateString(device.first_hello)}></InfoLine>
                <InfoLine label='Última conexão' value={getDateString(device.last_hello)}></InfoLine>
                <InfoLine label='Status de associação' value={AssocStatus[device.assoc_status]}></InfoLine>

            </div>

        </div>

    }

    const profileInfo = () => {

        return <div className='section'>

            <div className='device-info section-card'>

                <div className='subtitle'>Perfil de padrão de fábrica</div>

                <InfoLine label='Nome do perfil' value={device.profile.name}></InfoLine>
                <InfoLine label='Status do perfil' value={ProfileStatus[device.profile.status]}></InfoLine>
                <InfoLine label='ID do perfil' value={device.profile.profileid}></InfoLine>
                {device.profile.status === 2 &&
                    <InfoLine label='Confirmado em' value={getDateString(device.profile.confirmedTime * 1000)}></InfoLine>
                }

            </div>
        </div>
    }

    const devicesAction = () => {

        return <div className='section'>

            <div className='device-info section-card'>

                <div className='subtitle'>Ações</div>

                <Button text='Reenviar perfil' onClick={() => setReaplyConfirm(true)}></Button>
                <Button text='Desassociar' onClick={() => setConfirmDesassoc(true)}></Button>
            </div>
        </div>

    }

    const deleteAssociation = async(confirm) => {

        setConfirmDesassoc(false)

        if(!confirm) return

        setSaving(true)

        let result = await backend.delete(`devices/${device.deviceid}/association`)

        setSaving(false)

        if(result.status !== backendStatus.SUCCESS) {
            console.error(result.content)
            setError(true)
            return
        }

        searchDevice()

        setSuccess(true)
    }

    const reaplyProfile = async(confirm) => {

        setReaplyConfirm(false)

        if(!confirm) return

        setSaving(true)

        let result = await backend.update(`devices/${device.deviceid}/profile/status/reaply`)

        setSaving(false)

        if(result.status !== backendStatus.SUCCESS) {
            console.error(result.content)
            setError(true)
            return
        }

        searchDevice()

        setSuccess(true)
    }


    const closeModals = () => {
        setSuccess(false)
        setError(false)
        setSaving(false)
    }

    const confirmDesassocModal = () => {
        return <Modal
            content={<div>
                <b>Atenção!</b>
                <br></br>
                O dispositivo será dessasociado do provedor e o perfil de padrão de fábrica será
                programado para voltar ao padrão intelbras.
                <br></br>
                <br></br>
                <b>Deseja desassociar o dispositivo?</b>
            </div>}
            show={desassocConfirm}
            type={ModalTypes.CONFIRM_WARNING}
            confirmText={'Sim, quero desassociar'}
            dismissText={'Não, quero manter'}
            onDismissClick={deleteAssociation}
        ></Modal>
    }

    const confirmProfileModal = () => {
        return <Modal
            content={<div>
                <b>Atenção!</b>
                <br></br>
                O perfil de padrão de fábrica atual será programado para ser reenviado ao dispositivo.
                <br></br>
                <br></br>
                <b>Deseja reenviar o perfil?</b>
            </div>}
            show={reaplyConfirm}
            type={ModalTypes.CONFIRM_WARNING}
            confirmText={'Sim, quero reenviar'}
            dismissText={'Não, quero manter'}
            onDismissClick={reaplyProfile}
        ></Modal>
    }

    if(loading === null){
        return <Loading show={true}></Loading>
    }

    return <div className='devices-page'>

        <DefaultStatusModals
            success={success}
            saving={saving}
            error={error}
            continueFn={closeModals}
        ></DefaultStatusModals>

        {confirmDesassocModal()}
        {confirmProfileModal()}

        <Form onSubmit={searchDevice} id='devices-search' submitText='Buscar' buttonId='search-button'>
            <Input id='search-input'
                value={search}
                onChange={e => {
                    setSearch(e.target.value.toLowerCase())
                }}
                placeholder='ID do dispositivo'
            ></Input>

        </Form>

        <div className='flex-sections-container'>

            {device && deviceInfo()}
            {device && profileInfo()}
            {device && devicesAction()}

        </div>

    </div>
}