import React from "react";
import classNames from "classnames";
import styles from "./Label.module.css";

const Label = ({ children, element = "", className = "" }) => {
  return (
    <>
      <label
        className={classNames([
          styles.label,
          element === "checkbox" ? styles.checkbox : "",
          element === "radiobutton" ? styles.radiobutton : "",
          element === "toggle" ? styles.toggle : "",
          className,
        ])}
      >
        {children}
      </label>
    </>
  );
};

export default Label;
