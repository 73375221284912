import React, { useContext, useEffect } from "react";
import { UserContext } from "./user-context";

import Menu from "../components/menu/menu";
import MenuEntry from "../components/menu/menu-entry";
import DashboardIcon from "../components/icons/dashboard-icon";
import PeopleGroupIcon from "../components/icons/people-group-icon";
import ChecklistIcon from "../components/icons/checklist-icon";
import noAvatar from "../images/no-avatar.png";
import { MenuContext } from "../components/menu/menu-state";
import { useHistory } from "react-router";
import { DISABLE_AUTH, KeycloakContext } from "./keycloak";

export default function IdunMenu() {
  const history = useHistory();
  const user = useContext(UserContext);
  const menu = useContext(MenuContext);
  const keycloak = useContext(KeycloakContext);

  useEffect(() => {
    if (DISABLE_AUTH) return;

    keycloak.loadUserProfile().success((profile) => {
      menu.username.set(profile.firstName);
      menu.avatar.set(noAvatar);
    });
  });

  return (
    <Menu
      onLogout={() => {
        keycloak.logout();
      }}
    >
      <MenuEntry
        IconComponent={DashboardIcon}
        label="Usuários"
        description="Consulte e gerencie usuários"
        onClick={() => history.push("/")}
      ></MenuEntry>
      <MenuEntry
        IconComponent={DashboardIcon}
        label="Dispositivos"
        description="Consulte e gerencie dispositivos"
        onClick={() => history.push("/devices")}
      ></MenuEntry>
      <MenuEntry
        IconComponent={DashboardIcon}
        label="Provedores"
        description="Consulte e gerencie provedores"
        onClick={() => history.push("/isps")}
      ></MenuEntry>

      {user.type === 2 ? (
        <MenuEntry
          IconComponent={PeopleGroupIcon}
          label="Gerenciamento de usuários"
          description="Gerencie os usuários do help desk"
          onClick={() => history.push("/accounts")}
        ></MenuEntry>
      ) : null}
      {user.type === 2 ? (
        <MenuEntry
          IconComponent={ChecklistIcon}
          iconSize="20"
          label="Logs de operação"
          description="Visualize logs de consultas e alterações"
          onClick={() => history.push("/oplog")}
        ></MenuEntry>
      ) : null}
      <MenuEntry
        IconComponent={DashboardIcon}
        iconSize="20"
        label="Inmaster"
        subEntries={[
          <MenuEntry
            key="query-equipaments"
            IconComponent={ChecklistIcon}
            iconSize="20"
            label="Consultas"
            onClick={() => history.push("/inmaster/query-equipaments")}
          ></MenuEntry>,
        ]}
      ></MenuEntry>
    </Menu>
  );
}
