import classNames from 'classnames';
import React from 'react';
import { MdQuestionMark } from 'react-icons/md';
import styles from './ResultsNotFoundMessage.module.css';

const ResultsNotFoundMessage = ({
  id,
  height,
  minHeight,
  ...props
}) => {
  return (
    <div className='grid fluid'>
      <div className='row' {...props} style={{ height, minHeight }}>
        <div
          className={classNames([
            'col-xs-12', 
            styles.bodyMessage,
            height ? null : styles.fullPage
          ])}>
          <div
            className={classNames(['d-flex justify-center', styles.content])}>
            <div
              className={classNames([
                'd-flex justify-center align-center',
                styles.ball
              ])}>
              <span
                className={classNames([
                  'd-flex justify-center',
                  styles.iconInBall
                ])}>
                <MdQuestionMark size={33} />
              </span>
            </div>
            <div
              className={classNames(['d-flex flex-column align-start ml-4'])}
              id={`resultsNotFound-${id}`}>
              <h1 className="title-xl-lg">Resultado não encontrado</h1>
              <span className={classNames(['mt-3'])}>
                Não foi possível encontrar resultados com a busca realizada, experimente procurar por outros termos
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResultsNotFoundMessage.defaultProps = {
  height: null,
  minHeight: null
};

export { ResultsNotFoundMessage };
