import React, { useContext, useEffect, useState } from "react";
import HeaderDeviceDetails from "./HeaderDeviceDetails";
import AssociatedWireless from "./AssociatedWireless";
import RadioSettings from "./RadioSettings";
import styles from "./DeviceDetails.module.css";
import classNames from "classnames";
import Card from "../../libs/conecte-se/ui-components/Card";
import Tabs from "../../libs/conecte-se/ui-components/Tab/index";
import { MainHeaderContext } from "../../../../components/main-header/main-header-state";
import {
  Breadcrumbs,
  Crumb,
} from "../../libs/conecte-se/ui-components/Breadcrumbs";
import { useParams } from "react-router-dom";
import { api } from "../../services/api/api";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function DeviceDetails() {
  const { placeId, id: deviceId } = useParams();

  const [selectedDevice, setSelectedDevice] = useState();

  const getDevice = () => {
    return api.devices.details.get({ placeId: placeId, deviceId: deviceId });
  };

  const { isFetchedAfterMount: isFetchedAfterMountDeviceInfo, refetch: refetchDeviceInfo } = useQuery(
    ["deviceScreenQuery", deviceId],
    getDevice,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        data = { ...data, device: { ...data.device } };
        setSelectedDevice(data);
      },
    }
  );

  const tabs = [
    {
      label: "Wireless associadas",
      component: (
        <AssociatedWireless
          deviceOnScreen={selectedDevice}
          deviceWirelessList={selectedDevice && selectedDevice.wireless_list}
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
        />
      ),
    },
    {
      label: "Rádio",
      component: (
        <RadioSettings
          deviceOnScreen={selectedDevice}
          radios={selectedDevice && selectedDevice.radio_list}
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
        />
      ),
    },
  ];

  const header = useContext(MainHeaderContext);
  const history = useHistory();

  useEffect(() => {
    header.title.set(
      <Breadcrumbs>
        <Crumb>Consultas</Crumb>
        <Crumb>Visualizar Equipamento</Crumb>
      </Breadcrumbs>
    );
    const historyState = history.location;
    if(historyState.state === undefined) {
      header.backRoute.set("/inmaster/query-equipaments");
    }else{
      header.backRoute.set(historyState.state.prevPath);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classNames(styles.body)}>
      <div className="row fit-width">
        <span className="col-xs-12 title-xl-base fit-width">
          VISUALIZAR EQUIPAMENTO
        </span>
      </div>
      <Card
        background="var(--color-neutral-light-5)"
        className={classNames([styles.cardBody, "mt-6"])}
      >
        <HeaderDeviceDetails
          selectedDevice={selectedDevice || {}}
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
          hasConnectedClient
          hasRebootDevice
          hasUpdateFirmware
          hasWireless
          refetchDeviceInfo={refetchDeviceInfo}
        />

        <div className="row mt-8">
          <div className={classNames(["col-xs-12", styles.tabsContainer])}>
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DeviceDetails;
